<template>
    <BaseLayout>
        <div class="mx-auto mt-20 lg:mb-3 mb-28 lg:pr-3">
            <div class="grid gap-6 lg:px-0 px-3">
                <div class="bg-card p-4 rounded-lg shadow-md">
                    <h1 class="text-lg md:text-2xl text-muted-400 font-semibold">Informações gerais</h1>
                    <p class="text-muted-500 text-sm md:text-sm pb-2">Confira todas as informações da sua conta abaixo.
                    </p>
                    <form class="mb-5 grid grid-cols-1 items-center gap-3 md:grid-cols-2">
                        <div
                            class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="email">E-mail</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="!max-w-full nui-input w-full" id="email" name="email" type="text"
                                        v-model="user.email">
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-regular fa-envelope text-success-emphasis"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--@dev @EmersonGjR
                            Informação desnecessaria do id de usuario;
                        -->
                        <!--
                        <div
                            class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="idUser">ID do Usuário</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="!max-w-full nui-input w-full" id="idUser" name="userid" type="text"
                                        value="1" readonly disabled>
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <svg data-v-a8eacbc2="" xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                                            class="icon nui-input-icon-inner" width="1em" height="1em"
                                            viewBox="0 0 256 256">
                                            <g fill="currentColor">
                                                <path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96" opacity=".2">
                                                </path>
                                                <path
                                                    d="M72 128a134.63 134.63 0 0 1-14.16 60.47a8 8 0 1 1-14.32-7.12A118.8 118.8 0 0 0 56 128a71.73 71.73 0 0 1 27-56.2a8 8 0 1 1 10 12.49A55.76 55.76 0 0 0 72 128m56-8a8 8 0 0 0-8 8a184.12 184.12 0 0 1-23 89.1a8 8 0 0 0 14 7.76A200.19 200.19 0 0 0 136 128a8 8 0 0 0-8-8m0-32a40 40 0 0 0-40 40a8 8 0 0 0 16 0a24 24 0 0 1 48 0a214.09 214.09 0 0 1-20.51 92a8 8 0 1 0 14.51 6.83A230 230 0 0 0 168 128a40 40 0 0 0-40-40m0-64A104.11 104.11 0 0 0 24 128a87.76 87.76 0 0 1-5 29.33a8 8 0 0 0 15.09 5.33A103.9 103.9 0 0 0 40 128a88 88 0 0 1 176 0a282.24 282.24 0 0 1-5.29 54.45a8 8 0 0 0 6.3 9.4a8.22 8.22 0 0 0 1.55.15a8 8 0 0 0 7.84-6.45A298.37 298.37 0 0 0 232 128A104.12 104.12 0 0 0 128 24M94.4 152.17a8 8 0 0 0-9.4 6.25a151 151 0 0 1-17.21 45.44a8 8 0 0 0 13.86 8a166.67 166.67 0 0 0 19-50.25a8 8 0 0 0-6.25-9.44M128 56a72.85 72.85 0 0 0-9 .56a8 8 0 0 0 2 15.87A56.08 56.08 0 0 1 184 128a252.12 252.12 0 0 1-1.92 31a8 8 0 0 0 6.92 9a8.39 8.39 0 0 0 1 .06a8 8 0 0 0 7.92-7a266.48 266.48 0 0 0 2-33A72.08 72.08 0 0 0 128 56m57.93 128.25a8 8 0 0 0-9.75 5.75c-1.46 5.69-3.15 11.4-5 17a8 8 0 0 0 5 10.13a7.88 7.88 0 0 0 2.55.42a8 8 0 0 0 7.58-5.46c2-5.92 3.79-12 5.35-18.05a8 8 0 0 0-5.72-9.78Z">
                                                </path>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    -->
                        <div
                            class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="phone">Telefone</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="!max-w-full nui-input w-full" id="phone" name="phone" type="phone"
                                        v-model="user.phone">
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-regular fa-phone"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="py-2">
                        <button
                            class="lg:!w-fit w-full transition duration-700 text-white btn-gradient font-medium rounded-lg text-sm px-5 py-2.5 me-2 focus:outline-none"
                            type="button" @click="updateFirstField">
                            Salvar
                        </button>
                    </div>
                </div>
                <!-- Informações do Usuário -->
                <div class="bg-card p-4 rounded-lg py-5 shadow-md">
                    <h1 class="text-lg md:text-2xl text-muted-400 font-semibold">Informações do usuário</h1>
                    <p class="text-muted-500 text-sm md:text-sm pb-2">Confira todas as informações relacionadas ao seu
                        perfil de usuário.</p>
                    <form class="mb-5 grid grid-cols-1 items-center gap-3 md:grid-cols-2">
                        <!--@dev @EmersonGjR
                                Não tem coluna apelido
                            -->
                        <!--
                    <div
                        class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">

                        <label class="nui-input-label" for="apelido">Apelido</label>
                        <div class="nui-input-outer">
                            <div class="relative">
                                <input class="!max-w-full nui-input w-full" id="apelido" type="text" value="iFusion"
                                    required>
                                <div class="icon-form absolute top-0 bottom-0 left-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        aria-hidden="true" role="img" class="icon nui-input-icon-inner" width="1em"
                                        height="1em" viewBox="0 0 256 256" data-v-b4402e20="">
                                        <g fill="currentColor">
                                            <path d="M192 96a64 64 0 1 1-64-64a64 64 0 0 1 64 64" opacity=".2">
                                            </path>
                                            <path
                                                d="M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8M72 96a56 56 0 1 1 56 56a56.06 56.06 0 0 1-56-56">
                                            </path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>

                    </div>
                -->

                        <div
                            class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="name">Nome</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="!max-w-full nui-input w-full" id="name" name="name" type="text"
                                        v-model="user.name">
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                                            class="icon nui-input-icon-inner" width="1em" height="1em"
                                            viewBox="0 0 256 256" data-v-b4402e20="">
                                            <g fill="currentColor">
                                                <path d="M192 96a64 64 0 1 1-64-64a64 64 0 0 1 64 64" opacity=".2">
                                                </path>
                                                <path
                                                    d="M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8M72 96a56 56 0 1 1 56 56a56.06 56.06 0 0 1-56-56">
                                                </path>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="idUser">Moeda</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="!max-w-full nui-input w-full" id="idUser" name="userid" type="text"
                                        value="BRL" readonly disabled>
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-regular fa-money-bill-1"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="doc">Documento</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="!max-w-full nui-input w-full" id="doc" name="phone" type="phone"
                                        v-model="user.cpf">
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-regular fa-address-card"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <button
                        class="lg:!w-fit w-full transition duration-700 text-white btn-gradient font-medium rounded-lg text-sm px-5 py-2.5 me-2 focus:outline-none"
                        type="button" @click="updateSecondField">
                        Salvar
                    </button>
                </div>
                <div class="bg-card p-4 rounded-lg py-5 shadow-md">
                    <h1 class="text-lg md:text-2xl text-muted-400 font-semibold">Meu Pix</h1>
                    <p class="text-muted-500 text-sm md:text-sm pb-2">Veja e gerencie as informações do seu Pix.</p>
                    <form class="mb-5 grid grid-cols-1 items-center gap-3 md:grid-cols-2">
                        <div
                            class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="chavepix">Tipo de Chave</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <select class="!max-w-full nui-input w-full" id="chavepix" v-model="user.pix_type">
                                        <option selected>CPF/CNPJ</option>
                                        <option>Chave aleatória</option>
                                        <option>E-mail</option>
                                        <option>Telefone</option>
                                    </select>
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-regular fa-key"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="docPix">Chave pix</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="!max-w-full nui-input w-full" id="docPix" name="docPix" type="text"
                                        v-model="user.pix_key">
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-brands fa-pix"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <button
                        class="lg:!w-fit w-full transition duration-700 text-white btn-gradient font-medium rounded-lg text-sm px-5 py-2.5 me-2 focus:outline-none"
                        type="button" @click="updateThirdField">
                        Salvar
                    </button>
                </div>
                <div class="bg-card p-4 rounded-lg py-5 shadow-md">
                    <h1 class="text-lg md:text-2xl text-muted-400 font-semibold">Alterar Senha</h1>
                    <p class="text-muted-500 text-sm md:text-sm pb-2">Altere sua senha abaixo.</p>
                    <form class="mb-5 grid grid-cols-1 items-center gap-3 md:grid-cols-2">
                        <div
                            class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="confirmPassword">Senha Atual</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="!max-w-full nui-input w-full" id="confirmPassword"
                                        name="confirmPassword" type="password" v-model="user.confirmPassword">
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-regular fa-lock"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="newPassword">Nova Senha</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="!max-w-full nui-input w-full" id="newPassword" name="newPassword"
                                        type="password" v-model="user.newPassword">
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-regular fa-lock"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="newPasswordConfirm">Confirmar Senha</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="!max-w-full nui-input w-full" id="newPasswordConfirm"
                                        name="newPasswordConfirm" type="password" v-model="user.newPasswordConfirm">
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-regular fa-lock"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                    <button
                        class="lg:!w-fit w-full transition duration-700 text-white btn-gradient font-medium rounded-lg text-sm px-5 py-2.5 me-2 focus:outline-none"
                        type="submit" @click="updateFourthField">
                        Alterar Senha
                    </button>
                </div>
                <div class=" bg-card p-4 rounded-lg py-5 shadow-md">
                    <h1 class="text-lg md:text-2xl text-muted-400 font-semibold">Estatísticas da Conta</h1>
                    <p class="text-muted-500 text-sm md:text-sm pb-2">Visualize as estatísticas resumidas da sua
                        conta.
                    </p>
                    <form class="flex lg:flex-row flex-col gap-3 w-full">
                        <div
                            class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2 w-full">
                            <label class="nui-input-label" for="totalWin">Ganhos totais</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <div class="!max-w-full nui-input w-full px-4" id="totalWin"  >
                                        {{ user.win }}
                                    </div>
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-regular fa-key"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2 w-full">
                            <label class="nui-input-label" for="totalWin">Apostas totais</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <div class="!max-w-full nui-input w-full px-4" id="totalWin" >{{ user.total }} </div>
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-regular fa-key"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

            </div>


        </div>
        <!-- Informações de Pagamento -->

    </BaseLayout>
</template>


<script>

import BaseLayout from "@/Layouts/BaseLayout.vue";
import { useToast } from "vue-toastification";
import HttpApi from "@/Services/HttpApi.js";
export default {
    props: [],
    components: { BaseLayout },
    data() {
        return {
            originalInformation: {},
            user: {},
            isLoading: false,
        }
    },
    setup(props) {


        return {};
    },
    computed: {

    },
    async mounted() {
        await this.getUserBets();
        await this.getUserInfos();

    },
    methods: {
        updateFourthField: function () {
            const _this = this;
            const _toast = useToast();
            if (_this.user.newPassword === _this.user.newPasswordConfirm) {
                HttpApi.post('/profile/updateFourthField', {
                    confirmPassword: _this.user.confirmPassword,
                    newPassword: _this.user.newPassword
                })
                    .then(response => {
                        _toast.success("Senha atualizada com sucesso");
                        // Update the original name with the new name after successful update
                        _this.originalInformation.confirmPassword = _this.user.confirmPassword;
                        _this.originalInformation.newPassword = _this.user.newPassword;
                    })
                    .catch(error => {
                        _toast.error("Um erro ocorreu atualizando o perfil");
                    });
            } else {
                _toast.error("Senhas diferentes");

            }
        },
        updateThirdField: function () {
            const _this = this;
            const _toast = useToast();
            if (_this.originalInformation.pix_key != _this.user.pix_key) {
                HttpApi.post('/profile/updateThirdField', {
                    pix_key: _this.user.pix_key,
                    pix_type: _this.user.pix_type
                })
                    .then(response => {
                        _toast.success("Pix atualizado com sucesso!");
                        // Update the original name with the new name after successful update
                        _this.originalInformation.pix_key = _this.user.pix_key;
                        _this.originalInformation.pix_type = _this.user.pix_type;
                    })
                    .catch(error => {
                        _toast.error("Um erro ocorreu ao atualizar o pix");
                    });


            }
        },
        updateSecondField: function () {
            const _this = this;
            const name = _this.originalInformation.name == _this.user.name ? null : _this.user.name;
            const cpf = _this.originalInformation.cpf == _this.user.cpf ? null : _this.user.cpf;
            const _toast = useToast();
            HttpApi.post('/profile/updateSecondField', {
                name: name,
                cpf: cpf
            })
                .then(response => {
                    _toast.sucess("Nome e cpf atualizado com sucesso!");
                    // Update the original name with the new name after successful update
                    _this.originalInformation.name = _this.user.name;
                    _this.originalInformation.cpf = _this.user.cpf;
                })
                .catch(error => {
                    console.error(error);
                    _toast.error("ocorreu um erro ao atualizar o sistema");
                });


        },
        updateFirstField: function () {
            const _this = this;
            const email = _this.originalInformation.email == _this.user.email ? null : _this.user.email;
            const phone = _this.originalInformation.phone == _this.user.phone ? null : _this.user.phone;
            const _toast = useToast();
            HttpApi.post('/profile/updateFirstField', {
                email: email,
                phone: phone
            })
                .then(response => {
                    console.log(response.data);
                    _toast.sucess("Email e Telefone atualizados com sucesso");
                    // Update the original name with the new name after successful update
                    _this.originalInformation.email = _this.user.email;
                    _this.originalInformation.phone = _this.user.phone;
                })
                .catch(error => {
                    console.error(error);
                    _toast.error("Ocorreu um erro ao atualizar o perfil");
                });

        },
        async getUserInfos() {
            this.isLoading = true;
            try {
                const response = await HttpApi.get('/profile/getUserInformations');
                const data = response.data;
                this.user = {
                    email: data.email,
                    phone: data.phone,
                    name: data.name,
                    cpf: data.cpf,
                    pix_key: data.pix_key
                };
                this.originalInformation = { ...this.user };
            } catch (error) {
                this.error = error.response?.data?.message || 'An error occurred.';
                _toast.error(this.error); // Optionally show a toast message for the error
            } finally {
                this.isLoading = false;
            }
        },
        async getUserBets() {
            const _toast = useToast();
            this.isLoading = true;
            try {
                const response = await HttpApi.get('/profile/getUserBets');
                const data = response.data;
                this.user = {
                    total: data.total,
                    win: data.win
                };
            } catch (error) {
                this.error = error.response?.data?.message || 'An error occurred.';
                _toast.error(this.error); // Optionally show a toast message for the error
            } finally {
                this.isLoading = false;
            }
        }


        /*
                getUserBets: function () {
                    const _this = this;
                    const _toast = useToast();
                    HttpApi.get('/profile/getUserBets')
                        .then(response => {
                            const data = response.data;
                            _this.user = {
                                total: data.total,
                                win: data.win
                            };
        
                        }
                        )
                        .catch(error => {
                            _this.error = data.message || 'An error occurred.';
                            _this.isLoading = false;
                        });
        
                },
                getUserInfos: function () {
                    const _this = this;
                    HttpApi.get('/profile/getUserInformations')
                        .then(response => {
                            const data = response.data;
        
                            _this.user = {
                                email: data.email,
                                phone: data.phone,
                                name: data.name,
                                cpf: data.cpf,
                                pix_key: data.pix_key
        
                            };
                            _this.originalInformation = { ..._this.user };
                        }
                        )
                        .catch(error => {
        
                            _this.error = data.message || 'An error occurred.';
                            _this.isLoading = false;
                        });
                },
                */

    },
    watch: {

    },
};
</script>

<style scoped></style>