<template>
    <AuthLayout>
        <LoadingComponent :isLoading="isLoading">
            <div class="text-center">
                <span>{{ $t('Loading') }}</span>
            </div>
        </LoadingComponent>
        <div v-if="!isLoading" class="my-auto mt-36">
            <div class="px-4 py-5">
                <div class="min-h-[calc(100vh-565px)] flex flex-col items-center justify-center">
                    <div class="bg-muted-800 shadow-lg w-full rounded-lg md:mt-0 sm:max-w-md xl:p-0">
                        <div class="p-4 space-y-4 md:space-y-6 md:p-8">
                            <!-- <h1 class="mb-8 text-3xl text-center">{{ $t('Login') }}</h1> -->
                             <div v-if="setting" class="w-full p-0 hidden md:flex flex-col items-center justify-center">
                                <img :src="`/storage/`+setting.software_logo_black" alt="" class="h-8 mr-3 block dark:hidden " />
                                <img :src="`/storage/`+setting.software_logo_white" alt=""  class="h-8 mr-3 hidden dark:block" />
                             </div>

                            <div class="mt-4 px-4">
                                <form @submit.prevent="loginSubmit" method="post" action="" class="">
                                    <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                                        <label class="nui-input-label" for="email">E-mail</label>
                                        <div class="nui-input-outer">
                                            <div class="relative">
                                                <input class="nui-input" id="email" type="text" v-model="loginForm.email" name="email" :placeholder="$t('Enter email or phone')" required>
                                                <div class="icon-form absolute top-0 bottom-0 left-0">
                                                    <svg data-v-a8eacbc2="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon nui-input-icon-inner" width="1em" height="1em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96" opacity=".2"></path><path d="M72 128a134.63 134.63 0 0 1-14.16 60.47a8 8 0 1 1-14.32-7.12A118.8 118.8 0 0 0 56 128a71.73 71.73 0 0 1 27-56.2a8 8 0 1 1 10 12.49A55.76 55.76 0 0 0 72 128m56-8a8 8 0 0 0-8 8a184.12 184.12 0 0 1-23 89.1a8 8 0 0 0 14 7.76A200.19 200.19 0 0 0 136 128a8 8 0 0 0-8-8m0-32a40 40 0 0 0-40 40a8 8 0 0 0 16 0a24 24 0 0 1 48 0a214.09 214.09 0 0 1-20.51 92a8 8 0 1 0 14.51 6.83A230 230 0 0 0 168 128a40 40 0 0 0-40-40m0-64A104.11 104.11 0 0 0 24 128a87.76 87.76 0 0 1-5 29.33a8 8 0 0 0 15.09 5.33A103.9 103.9 0 0 0 40 128a88 88 0 0 1 176 0a282.24 282.24 0 0 1-5.29 54.45a8 8 0 0 0 6.3 9.4a8.22 8.22 0 0 0 1.55.15a8 8 0 0 0 7.84-6.45A298.37 298.37 0 0 0 232 128A104.12 104.12 0 0 0 128 24M94.4 152.17a8 8 0 0 0-9.4 6.25a151 151 0 0 1-17.21 45.44a8 8 0 0 0 13.86 8a166.67 166.67 0 0 0 19-50.25a8 8 0 0 0-6.25-9.44M128 56a72.85 72.85 0 0 0-9 .56a8 8 0 0 0 2 15.87A56.08 56.08 0 0 1 184 128a252.12 252.12 0 0 1-1.92 31a8 8 0 0 0 6.92 9a8.39 8.39 0 0 0 1 .06a8 8 0 0 0 7.92-7a266.48 266.48 0 0 0 2-33A72.08 72.08 0 0 0 128 56m57.93 128.25a8 8 0 0 0-9.75 5.75c-1.46 5.69-3.15 11.4-5 17a8 8 0 0 0 5 10.13a7.88 7.88 0 0 0 2.55.42a8 8 0 0 0 7.58-5.46c2-5.92 3.79-12 5.35-18.05a8 8 0 0 0-5.72-9.78Z"></path></g></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                                        <label class="nui-input-label" for="password">Password</label>
                                        <div class="nui-input-outer">
                                            <div class="relative">
                                                <input class="nui-input" id="password" :type="typeInputPassword" v-model="loginForm.password" name="password" :placeholder="$t('Type the password')" required>
                                                <div class="icon-form absolute top-0 bottom-0 left-0">
                                                    <i class="fa-regular fa-lock text-success-emphasis"></i>
                                                </div>
                                                <button @click.prevent="togglePassword" class="h-[55px] leading-0 text-muted-400 peer-focus-within:text-primary-500 nui-focus absolute right-2 top-0 bottom-0 flex px-2 items-center justify-center text-center text-xl disabled:cursor-not-allowed [&amp;_.nui-text-button]:rounded-s-xl" type="button" tabindex="0" data-nui-tooltip="Hide password">
                                                    <div class="relative flex size-full items-center justify-center">
                                                        <i v-if="typeInputPassword === 'password'" class="fa-regular fa-eye"></i>
                                                        <i v-if="typeInputPassword === 'text'" class="fa-sharp fa-regular fa-eye-slash"></i>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-end">
                                        <a href="/forgot-password" class="text-white dark:text-[#94a3b8cc] text-sm">Esqueceu a senha?</a>
                                    </div>
                                    <div class="mt-3 w-full mb-2">
                                        <button type="submit" class="transition duration-700 text-white button-primary font-medium rounded-lg text-sm px-5 py-2.5 me-2 focus:outline-none w-full flex items-center justify-center gap-3">
                                            {{ $t('Log in') }}
                                        </button>
                                    </div>
                                    <div class="flex justify-between dark:text-[#94a3b8cc] text-[12px] mb-3 text-center">
                                        <p class="">Novo por aqui?</p>
                                        <a href="/register">
                                            <strong class="dark:text-gray-400">Criar conta</strong>
                                        </a>
                                    </div>
                                </form>
                                <div class="login-wrap my-5">
                                    <div class="line-text">
                                        <div class="l"></div>
                                        <div class="t">{{ $t('Register with your social networks') }}</div>
                                        <div class="l"></div>
                                    </div>
                                    <div class="my-5">
                                        <a :href="redirectSocialTo()" class="dark:bg-[#0f172a] text-gray-800 border-gray-300 dark:border-[#334155] nui-focus relative inline-flex grow items-center justify-center gap-2 rounded-xl border bg-white px-6 py-2 dark:text-white w-full">
                                            <svg data-v-a8eacbc2="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon h-5 w-5" width="1em" height="1em" viewBox="0 0 256 262"><path fill="#4285F4" d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622l38.755 30.023l2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"></path><path fill="#34A853" d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055c-34.523 0-63.824-22.773-74.269-54.25l-1.531.13l-40.298 31.187l-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"></path><path fill="#FBBC05" d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82c0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602z"></path><path fill="#EB4335" d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0C79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"></path></svg>
                                            <div class="dark:text-gray-400">Login com Google</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AuthLayout>
</template>


<script>

import {useToast} from "vue-toastification";
import {useAuthStore} from "@/Stores/Auth.js";
import HttpApi from "@/Services/HttpApi.js";
import AuthLayout from "@/Layouts/AuthLayout.vue";
import {useRouter} from "vue-router";
import {useSettingStore} from "@/Stores/SettingStore.js";
import LoadingComponent from "@/Components/UI/LoadingComponent.vue";

export default {
    props: [],
    components: { LoadingComponent, AuthLayout },
    data() {
        return {
            isLoading: false,
            typeInputPassword: 'password',
            isReferral: false,
            loginForm: {
                email: '',
                password: '',
            },
        }
    },
    setup(props) {
        const router = useRouter();
        return {
            router
        };
    },
    computed: {
        isAuthenticated() {
            const authStore = useAuthStore();
            return authStore.isAuth;
        },
        setting() {
            const authStore = useSettingStore();
            return authStore.setting;
        }
    },
    mounted() {
        const router = useRouter();
        if(this.isAuthenticated) {
            router.push({ name: 'home' });
        }
    },
    methods: {
        redirectSocialTo: function() {
            return '/auth/redirect/google'
        },
        loginToggle: function() {
            this.modalAuth.toggle();
        },
        loginSubmit: async function(event) {
            const _this = this;
            const _toast = useToast();
            _this.isLoading = true;
            const authStore = useAuthStore();

            await HttpApi.post('auth/login', _this.loginForm)
                .then(async response =>  {
                    await new Promise(r => {
                        setTimeout(() => {
                            authStore.setToken(response.data.access_token);
                            authStore.setUser(response.data.user);
                            authStore.setIsAuth(true);

                            _this.loginForm = {
                                email: '',
                                password: '',
                            }

                            _this.router.push({ name: 'home' });
                            _toast.success(_this.$t('Você foi autenticado! Bem vindo!'));

                            _this.isLoading = false;
                        }, 1000)
                    });

                })
                .catch(error => {
                    const _this = this;
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        _toast.error(`${value}`);
                    });
                    _this.isLoading = false;
                });
        },
        togglePassword: function() {
            if(this.typeInputPassword === 'password') {
                this.typeInputPassword = 'text';
            }else{
                this.typeInputPassword = 'password';
            }
        },
    },
    watch: {

    },
};
</script>

<style scoped>

</style>
