<template>
    <BaseLayout :showFooter="false">
        <div class="mx-auto p-4 mt-20">
            <!---->
            <div class="grid grid-cols-1 md:grid-cols-3">
                <div>
                    <div class="hidden md:block">
                        <div class="flex flex-col h-[calc(100vh-150px)] p-5">
                            <div class="relative bg-card flex flex-col justify-center items-center rounded-lg px-5 py-16 col-span-1">
                                algo escrito
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative col-span-2 lg:pr-4 lg:px-0 px-3">
                    <div class="flex flex-col w-full">
                        <div class="mt-5 flex flex-col bg-card p-4 rounded-lg">
                            <DepositWidget class="border-none" />
                        </div>
                    </div>
                </div> 
                 <div class="col-span-1 hidden md:block">
                    <WalletSideMenu />
                </div> 
                 <div class="col-span-2 relative">
                    <DepositWidget />
                </div> 
             </div> 
             <!---->
        </div>
    </BaseLayout>
</template>


<script>

import { RouterLink } from "vue-router";
import BaseLayout from "@/Layouts/BaseLayout.vue";
import DepositWidget from "@/Components/Widgets/DepositWidget.vue";
import WalletSideMenu from "@/Pages/Profile/Components/WalletSideMenu.vue";
import {useAuthStore} from "@/Stores/Auth.js";

export default {
    props: [],
    components: {WalletSideMenu, DepositWidget, BaseLayout, RouterLink },
    data() {
        return {
            isLoading: false,

        }
    },
    setup(props) {


        return {};
    },
    computed: {
        isAuthenticated() {
            const authStore = useAuthStore();
            return authStore.isAuth;
        },
    },
    mounted() {

    },
    methods: {

    },
    created() {

    },
    watch: {

    },
};
</script>

<style scoped>

</style>
