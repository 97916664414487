<template>
    <BaseLayout :showFooter="false">
        <LoadingComponent :isLoading="isLoading"></LoadingComponent>
        <div v-if="!isLoading" class="mx-auto mt-20">
            <div v-if="wallet" class="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-4 lg:px-0 px-4">
                <div v-if="isShowForm">
                    <div class="col-span-1">
                        <div class="bg-card rounded-2xl shadow-lg relative mb-5">
                            <div class="flex items-center justify-between">
                                <div class="flex items-center mb-5 p-5">
                                    <div class="mr-3">
                                        <img src="/assets/images/invite.png" alt="" width="60">
                                    </div>
                                    <div>
                                        <h1 class="text-2xl text-muted-500">Links de Convite</h1>
                                    </div>
                                </div>
                                <div class="absolute right-5 top-5">
                                    <button type="button" @click.prevent="generateCode" data-nui-tooltip-position="right" data-nui-tooltip="Gerar novo link" class="bg-muted-900/20 border border-muted-0029 rounded-lg px-3 py-2">
                                        <i class="fa-regular fa-arrows-rotate"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="border-muted-0029 dark:border-muted-0029 mb-8 space-y-5 border-b-2 border-dashed px-6 pb-6">
                                <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                                    <label class="nui-input-label" for="referenceCode">Código de Referência</label>
                                    <div class="nui-input-outer">
                                        <div class="relative">
                                            <input class="nui-input font-semibold" id="referenceCode" type="text" v-model="referencecode" :placeholder="$t('Reference Code')" required>
                                            <div class="icon-form absolute top-0 bottom-0 left-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon nui-input-icon-inner" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="12" r="10"></circle><path d="M12 2a14.5 14.5 0 0 0 0 20a14.5 14.5 0 0 0 0-20M2 12h20"></path></g></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <button @click.prevent="copyCode" type="submit" class="btn-gradient nui-button nui-button-large nui-button-rounded nui-button-solid w-full text-sm font-extrabold rounded-lg border-primary flex items-center justify-center p-4 cursor-pointer">
                                        <i class="fa-duotone fa-copy mr-2"></i> 
                                        Copiar Código
                                    </button>
                                </div>
                            </div>
                            <div class="border-muted-0029 dark:border-muted-0029 mb-8 space-y-5 px-6 pb-6">
                                <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                                    <label class="nui-input-label" for="referenceCode">Código de Referência</label>
                                    <div class="nui-input-outer">
                                        <div class="relative">
                                            <input class="!max-w-full w-full nui-input font-semibold" id="referenceLink" type="text" v-model="referencelink" :placeholder="$t('Reference Link')" required>
                                            <div class="icon-form absolute top-0 bottom-0 left-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon nui-input-icon-inner" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="12" r="10"></circle><path d="M12 2a14.5 14.5 0 0 0 0 20a14.5 14.5 0 0 0 0-20M2 12h20"></path></g></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <button @click.prevent="copyLink" type="submit" class="btn-gradient nui-button nui-button-large nui-button-rounded nui-button-solid w-full text-sm font-extrabold rounded-lg border-primary flex items-center justify-center p-4 cursor-pointer">
                                        <i class="fa-duotone fa-copy mr-2"></i> 
                                        Copiar link de referência
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="bg-card p-5 lg:px-2 px-4 rounded-2xl shadow-lg">
                    <h1 class="text-2xl text-muted-400">Sistema de Afiliados</h1>
                    <p class="text-muted-500 text-[12px]"> {{ $t('Generate the code Description') }}</p>
                    <div class="mt-5 grid grid-cols-2">
                        <div class="mr-2 p-3">
                            <img src="/assets/images/invite.png" alt="" width="250" class="">
                        </div>
                        <div class="flex flex-col justify-center items-center">
                            <button @click.prevent="generateCode" type="button" class="justify-center btn-gradient nui-button nui-button-large nui-button-rounded nui-button-solid w-full text-sm font-extrabold rounded-lg border-primary h-10 flex items-center">
                                {{ $t('Generate the code') }}
                            </button>
                            <a href="" class="mt-2 text-[12px] text-muted-500 hover:text-blue-500">Termos de Afiliados</a>
                        </div>
                    </div>
                </div>
                <div class="col-span-2 w-full lg:py-0 py-3 lg:pr-4">
                        <div>
                            <div class="lg:px-2 px-4">
                                <div class="mb-6 flex justify-between gap-y-4 sm:flex-row sm:items-center">
                                    <div>
                                        <p class="nui-paragraph nui-paragraph-sm nui-weight-normal nui-lead-normal text-muted-500 dark:text-muted-400">Saldo Atual</p>
                                        <p class="nui-heading nui-heading-4xl nui-weight-medium nui-lead-normal text-muted-800 dark:text-white">
                                            <span class="after:text-success-500 after:relative after:-end-2 after:-top-3 after:text-sm font-semibold text-xl">{{ state.currencyFormat(parseFloat(wallet.refer_rewards), wallet.currency) }}</span>
                                        </p>
                                    </div>
                                    <div>
                                        <div class="flex gap-1">
                                            <a href="/affiliate/login" target="_blank" class="nui-card nui-card-default py-4 px-6 h-full ml-3 flex items-center justify-center rounded">Painel</a>
                                            <button @click.prevent="opemModalWithdrawal" type="button" class="btn-gradient py-4 px-6 h-full ml-3 flex items-center justify-center rounded">{{ $t('Withdraw') }}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid gap-4 sm:grid-cols-3 mb-5">
                                    <div class="relative">
                                        <div class="nui-card nui-card-rounded-md nui-card-default flex gap-3 p-5">
                                            <div class="shrink-0">
                                                <img src="/assets/images/cofre.png" alt="" class="size-12">
                                            </div>
                                            <div>
                                                <p class="nui-paragraph nui-paragraph-xs nui-weight-normal nui-lead-normal text-muted-500 dark:text-muted-400">Comissão CPA</p>
                                                <h5 class="nui-heading nui-heading-md nui-weight-medium nui-lead-normal text-muted-800 dark:text-white">
                                                    <span>{{ state.currencyFormat(parseFloat(userData.affiliate_cpa), wallet.currency) }}</span>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative">
                                        <div class="nui-card nui-card-rounded-md nui-card-default flex gap-3 p-5">
                                            <div class="shrink-0">
                                                <img src="/assets/images/cofre.png" alt="" class="size-12">
                                            </div>
                                            <div>
                                                <p class="nui-paragraph nui-paragraph-xs nui-weight-normal nui-lead-normal text-muted-500 dark:text-muted-400">Comissão de RevShare</p>
                                                <h5 class="nui-heading nui-heading-md nui-weight-medium nui-lead-normal text-muted-800 dark:text-white">
                                                    <span>{{ userData.affiliate_revenue_share_fake ? userData.affiliate_revenue_share_fake : userData.affiliate_revenue_share }}%</span>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative">
                                        <div class="nui-card nui-card-rounded-md nui-card-default flex gap-3 p-5">
                                            <div class="shrink-0">
                                                <img src="/assets/images/cofre.png" alt="" class="size-12">
                                            </div>
                                            <div>
                                                <p class="nui-paragraph nui-paragraph-xs nui-weight-normal nui-lead-normal text-muted-500 dark:text-muted-400">Comissões Totais</p>
                                                <h5 class="nui-heading nui-heading-md nui-weight-medium nui-lead-normal text-muted-800 dark:text-white">
                                                    <span>{{ state.currencyFormat(parseFloat(wallet.refer_rewards), wallet.currency) }}</span>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pb-20"></div>
                            </div>
                        </div>
                    </div>
            </div>
            <!-- <div v-else role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 h-full mt-16">
                <div class="text-center flex flex-col justify-center items-center">
                    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                    <span class="mt-3">{{ $t('Loading') }}...</span>
                </div>
            </div> -->
        </div>

        <!-- MODAL RECOMPENSAS DE REFERÊNCIA -->
        <div id="referenceRewardsEl" tabindex="-1" aria-hidden="true" class="fixed left-0 right-0 top-0 z-50 hidden h-[calc(100%-1rem)] max-h-full w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0">
            <div class="relative max-h-full w-full max-w-2xl">
                <!-- Modal content -->
                <div class="relative rounded-lg bg-white shadow dark:bg-gray-700">

                    <!-- Modal header -->
                    <div class="flex justify-between p-4 dark:bg-gray-600 rounded-t-lg">
                        <h1>{{ $t('Referral Reward Rules') }}</h1>
                        <button class="" @click.prevent="toggleReferenceRewards">
                            <i class="fa-solid fa-xmark"></i>
                        </button>
                    </div>

                    <!-- Modal body -->
                    <div class="w-full flex justify-center p-4">
                        <div class="flex items-center">
                            <div class="l"></div>
                            <div class="text-white px-3">
                                Regras de Desbloqueio
                            </div>
                            <div class="r"></div>
                        </div>


                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL RECOMPENSAS POR COMISSÃO -->
        <div id="commissionRewardsEl" tabindex="-1" aria-hidden="true" class="fixed left-0 right-0 top-0 z-50 hidden h-[calc(100%-1rem)] max-h-full w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0">
            <div class="relative max-h-full w-full max-w-2xl">
                <!-- Modal content -->
                <div class="relative rounded-lg bg-white shadow dark:bg-gray-700">

                    <!-- Modal header -->
                    <div class="flex justify-between p-4 dark:bg-gray-600 rounded-t-lg">
                        <h1>Regras de recompensas por comissão</h1>
                        <button class="" @click.prevent="toggleCommissionRewards">
                            <i class="fa-solid fa-xmark"></i>
                        </button>
                    </div>

                    <!-- Modal body -->
                    <div class="flex flex-col  w-full justify-center p-4">
                        <div class="flex items-center text-center w-full justify-center">
                            <div class="l"></div>
                            <div class="text-white px-3">
                                Taxas de comissões
                            </div>
                            <div class="r"></div>
                        </div>

                        <div class="mt-5">
                            <ul>
                                <li class="flex dark:bg-gray-800 shadow rounded-lg aposta-1 w-full p-4 mb-3">
                                    <div>
                                        <h1 class="font-mono text-4xl font-bold">7%</h1>
                                        <p class="text-gray-400 text-sm"><strong class="text-gray-400">Jogo:</strong> Os Jogos Originais</p>
                                    </div>
                                </li>
                                <li class="flex dark:bg-gray-800 shadow rounded-lg aposta-2 w-full p-4 mb-3">
                                    <div>
                                        <h1 class="font-mono text-4xl font-bold">7%</h1>
                                        <p class="text-gray-400 text-sm"><strong class="text-gray-400">Jogo:</strong> slots de terceiros, cassino ao vivo</p>
                                    </div>
                                </li>
                                <li class="flex dark:bg-gray-800 shadow rounded-lg aposta-3 w-full p-4 mb-3">
                                    <div>
                                        <h1 class="font-mono text-4xl font-bold">25%</h1>
                                        <p class="text-gray-400 text-sm"><strong class="text-gray-400">Jogo:</strong> Esportes</p>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="mt-5 ml-4">
                            <ul class="list-outside list-disc">
                                <li class="mb-3">
                                    Em qualquer ambiente público (por exemplo, universidades, escolas, bibliotecas e espaços de escritório), apenas uma comissão pode ser paga para cada usuário,
                                    endereço IP, dispositivo eletrônico, residência, número de telefone, método de cobrança, endereço de e-mail e computador e IP endereço compartilhado com outras pessoas.
                                </li>
                                <li class="mb-3">
                                    Nossa decisão de fazer uma aposta será baseada inteiramente em nosso critério depois que um depósito for feito e uma aposta for feita com sucesso.
                                </li>
                                <li class="mb-3">
                                    As comissões podem ser retiradas em nossa carteira CREDK interna do painel a qualquer momento. (Veja a extração de sua comissão no painel e visualize o saldo na carteira).
                                </li>
                                <li class="mb-3">
                                    Apoiamos a maioria das moedas no mercado.
                                </li>
                                <li class="mb-3">
                                    O sistema calcula a comissão a cada 24 horas.
                                </li>
                            </ul>
                        </div>

                        <div class="mt-5 w-full border dark:border-gray-500 p-4 rounded">
                            Se você tiver alguma dúvida sobre nossas regras, por favor <a href="" class="text-green-500 font-bold"> Contate-nos </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL SAQUE -->
        <div id="withdrawalEl" tabindex="-1" aria-hidden="true" class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full z-[99999] hidden">
            <div class="md:max-w-[450px] bg-modal bg-login max-h-full rounded-[50px] shadow-2xl inline-table">
                <div class="md:w-[450px] p-[30px] max-h-full">
                    <div class="modal-header"></div>
                    <div class="modal-body">
                        <div class="relative rounded-lg">
                            <div class="flex justify-between md:p-4 md:gap-0 p-2 gap-3">
                                <h1>Regras de recompensas por comissão</h1>
                                <button @click.prevent="opemModalWithdrawal">
                                    <i class="fa-solid fa-xmark text-lg"></i>
                                </button>
                            </div>
                            <div class="flex flex-col w-full justify-center p-4">
                                <form action="" @submit.prevent="makeWithdrawal">
                                    <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                                        <label class="nui-input-label" for="saqueValor">Valor do Saque</label>
                                        <div class="nui-input-outer">
                                            <div class="relative">
                                                <input class="nui-input" v-model="withdrawalForm.amount" type="number" id="saqueValor" placeholder="Valor do saque" required>
                                                <div class="icon-form absolute top-0 bottom-0 left-0">
                                                    <i class="fa-regular fa-money-bill"></i>
                                                </div>
                                            </div>
                                            <span v-if="wallet" class="text-sm italic">Saldo: {{ state.currencyFormat(parseFloat(wallet?.refer_rewards), wallet?.currency) }}</span>
                                        </div>
                                    </div>
                                    <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                                        <label class="nui-input-label" for="saqueKey">Chave Pix</label>
                                        <div class="nui-input-outer">
                                            <div class="relative">
                                                <input class="nui-input" v-model="withdrawalForm.pix_key" type="text" id="saqueKey" placeholder="Digite a sua Chave pix" required>
                                                <div class="icon-form absolute top-0 bottom-0 left-0">
                                                    <i class="fa-regular fa-key"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                                        <label class="nui-input-label" for="typeChave">Tipo de Chave</label>
                                        <div class="nui-input-outer">
                                            <div class="relative">
                                                <select class="nui-input" v-model="withdrawalForm.pix_type" id="typeChave" name="type_document" required>
                                                    <option value="">Selecione uma chave</option>
                                                    <option value="document">CPF/CNPJ</option>
                                                    <option value="email">E-mail</option>
                                                    <option value="phoneNumber">Telefone</option>
                                                    <option value="randomKey">Chave Aleatória</option>
                                                </select>
                                                <div class="icon-form absolute top-0 bottom-0 left-0">
                                                    <i class="fa-regular fa-key"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" class="mt-5 w-full rounded-full bg-green-500 text-white hover:bg-green-600 p-3 transition duration-700"> 
                                        Solicitar agora <i class="fa-regular fa-arrow-right ml-2"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>


<script>

import BaseLayout from "@/Layouts/BaseLayout.vue";
import { Modal } from 'flowbite';
import HttpApi from "@/Services/HttpApi.js";
import {useToast} from "vue-toastification";
import {useAuthStore} from "@/Stores/Auth.js";
import {useRouter} from "vue-router";
import LoadingComponent from "@/Components/UI/LoadingComponent.vue";

export default {
    props: [],
    components: { BaseLayout, Modal, LoadingComponent },
    data() {
        return {
            isLoading: true,
            referenceRewards: null,
            commissionRewards: null,
            isShowForm: false,
            isLoadingGenerate: false,
            code: '',
            urlCode: '',
            referencecode: '',
            referencelink: '',
            wallet: null,
            indications: 0,
            histories: null,
            withdrawalModal: null,
            withdrawalForm: {
                amount: 0,
                pix_key: '',
                pix_type: '',
            }
        }
    },
    setup(props) {
        const router = useRouter();
        return {
            router
        };
    },
    computed: {
        userData() {
            const authStore = useAuthStore();
            return authStore.user;
        }
    },
    mounted() {
        this.referenceRewards = new Modal(document.getElementById('referenceRewardsEl'), {
            placement: 'center',
            backdrop: 'dynamic',
            backdropClasses: 'bg-primary-opacity fixed inset-0 z-40',
            closable: true,
            onHide: () => {

            },
            onShow: () => {

            },
            onToggle: () => {

            },
        });

        this.commissionRewards = new Modal(document.getElementById('commissionRewardsEl'), {
            placement: 'center',
            backdrop: 'dynamic',
            backdropClasses: 'bg-primary-opacity fixed inset-0 z-40',
            closable: true,
            onHide: () => {

            },
            onShow: () => {

            },
            onToggle: () => {

            },
        });

        this.withdrawalModal = new Modal(document.getElementById('withdrawalEl'), {
            placement: 'center',
            backdrop: 'dynamic',
            backdropClasses: 'bg-primary-opacity fixed inset-0 z-40',
            closable: false,
            onHide: () => {

            },
            onShow: () => {

            },
            onToggle: () => {

            },
        });
    },
    methods: {
        copyCode: function(event) {
            const _toast = useToast();
            var inputElement = document.getElementById("referenceCode");
            inputElement.select();
            inputElement.setSelectionRange(0, 99999);  // Para dispositivos móveis

            // Copia o conteúdo para a área de transferência
            document.execCommand("copy");
            _toast.success(this.$t('Code copied successfully'));
        },
        copyLink: function(event) {
            const _toast = useToast();
            var inputElement = document.getElementById("referenceLink");
            inputElement.select();
            inputElement.setSelectionRange(0, 99999);  // Para dispositivos móveis

            // Copia o conteúdo para a área de transferência
            document.execCommand("copy");
            _toast.success(this.$t('Link copied successfully'));
        },

        getCode: function() {
            const _this = this;
            const _toast = useToast();
            _this.isLoadingGenerate = true;
            _this.isLoading = true;

            HttpApi.get('profile/affiliates/')
                .then(response => {
                    console.log("Full response:", response); 
                    if( response.data.code !== '' && response.data.code !== undefined && response.data.code !== null) {
                        _this.isShowForm = true;
                        _this.code          = response.data.code;
                        _this.referencecode = response.data.code;
                        _this.urlCode       = response.data.url;
                    }

                    _this.indications   = response.data.indications;
                    _this.referencelink = response.data.url;
                    _this.wallet        = response.data.wallet;
                    _this.withdrawalForm.amount = response.data.wallet.refer_rewards;

                    _this.isLoadingGenerate = false;
                    _this.isLoading = false;

                })
                .catch(error => {
                    _this.isShowForm = false;
                    _this.isLoadingGenerate = false;
                    _this.isLoading = false;
                });
        },
        generateCode: function(event) {
            const _this = this;
            const _toast = useToast();
            _this.isLoadingGenerate = true;
            _this.isLoading = true;

            HttpApi.get('profile/affiliates/generate')
                .then(response => {
                    if(response.data.status) {
                        _this.getCode();
                        _toast.success(_this.$t('Your code was generated successfully'));
                    }

                    _this.isLoadingGenerate = false;
                    _this.isLoading = false;
                })
                .catch(error => {
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        _toast.error(`${value}`);
                    });
                    _this.isLoadingGenerate = false;
                    _this.isLoading = false;
                });
        },
        toggleCommissionRewards: function(event) {
            this.commissionRewards.toggle();
        },
        toggleReferenceRewards: function(event) {
            this.referenceRewards.toggle();
        },
        opemModalWithdrawal: function() {
            this.withdrawalModal.toggle();
        },
        makeWithdrawal: async function() {
            const _this = this;
            const _toast = useToast();

            _this.isLoading = true;

            HttpApi.post('profile/affiliates/request', _this.withdrawalForm)
                .then(response => {
                    _this.opemModalWithdrawal();

                    _toast.success(_this.$t(response.data.message));
                    _this.isLoading = false;
                    _this.router.push({ name: 'profileWallet' });
                })
                .catch(error => {
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        _toast.error(`${value}`);
                    });
                    _this.isLoading = false;
                });
        }
    },
    created() {
      this.getCode();
    },
    watch: {

    },
};
</script>

<style scoped>

</style>
