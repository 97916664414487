<template>
    <!-- Drawer  -->
    <div id="drawer-menu" :class="[sidebar === true ? ' ' : 'translate-x-full translate-y-full']"
        class="md:hidden fixed z-20 w-full overflow-y-auto bg-white border-t border-gray-200 rounded-t-lg dark:border-gray-700 dark:bg-gray-800 transition-transform bottom-0 left-0 right-0 top-[70px]">
        <div class="p-4 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700">
            <span
                class="absolute w-8 h-1 -translate-x-1/2 bg-gray-300 rounded-lg top-3 left-1/2 dark:bg-gray-600"></span>
            <h5 id="drawer-swipe-label"
                class="inline-flex items-center text-base text-gray-500 dark:text-gray-400 font-medium">
                <svg class="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                    viewBox="0 0 18 18">
                    <path
                        d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10ZM17 13h-2v-2a1 1 0 0 0-2 0v2h-2a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2Z">
                    </path>
                </svg>
                Menu de Navegação
            </h5>
        </div>
        <div class="grid grid-cols-3 gap-4 p-4 lg:grid-cols-4">
            <div
                class="text-center p-4 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:hover:bg-gray-600 dark:bg-muted-700/10">
                <RouterLink :to="{ name: 'home' }">
                    <div
                        class="flex justify-center items-center p-2 mx-auto mb-2 bg-gray-200 dark:bg-muted-600/20 rounded-full w-[48px] h-[48px] max-w-[48px] max-h-[48px]">
                        <div class="transition duration-700 h-full w-full rounded-2xl flex items-center justify-center">
                            <i class="fa-solid fa-house-chimney"></i>
                        </div>
                    </div>
                    <span class="font-medium text-center text-gray-500 dark:text-gray-400">{{ $t('Home') }}</span>
                </RouterLink>
            </div>
            <div
                class="text-center p-4 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:hover:bg-gray-600 dark:bg-muted-700/10">
                <RouterLink :to="{ name: 'profileWallet' }">
                    <div
                        class="flex justify-center items-center p-2 mx-auto mb-2 bg-gray-200 dark:bg-muted-600/20 rounded-full w-[48px] h-[48px] max-w-[48px] max-h-[48px]">
                        <div class="transition duration-700 h-full w-full rounded-2xl flex items-center justify-center">
                            <i class="fa-duotone fa-wallet"></i>
                        </div>
                    </div>
                    <span class="font-medium text-center text-gray-500 dark:text-gray-400">{{ $t('Wallet') }}</span>
                </RouterLink>
            </div>
            <div v-for="(category, index) in categories" :key="index"
                class="text-center p-4 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:hover:bg-gray-600 dark:bg-muted-700/10">
                <RouterLink :to="{ name: 'casinosAll', params: { provider: 'all', category: category.slug } }">
                    <div
                        class="flex justify-center items-center p-2 mx-auto mb-2 bg-gray-200 dark:bg-muted-600/20 rounded-full w-[48px] h-[48px] max-w-[48px] max-h-[48px]">
                        <div class="transition duration-700 h-full w-full rounded-2xl flex items-center justify-center">
                            <img :src="`/storage/` + category.image" alt="" width="20">
                        </div>
                    </div>
                    <span class="font-medium text-center text-gray-500 dark:text-gray-400">{{ $t(category.name)
                        }}</span>
                </RouterLink>
            </div>
            <div
                class="text-center p-4 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:hover:bg-gray-600 dark:bg-muted-700/10">
                <RouterLink :to="{ name: 'casinos' }">
                    <div
                        class="flex justify-center items-center p-2 mx-auto mb-2 bg-gray-200 dark:bg-muted-600/20 rounded-full w-[48px] h-[48px] max-w-[48px] max-h-[48px]">
                        <div class="transition duration-700 h-full w-full rounded-2xl flex items-center justify-center">
                            <i class="fa-duotone fa-wallet"></i>
                        </div>
                    </div>
                    <span class="font-medium text-center text-gray-500 dark:text-gray-400">{{ $t('Favorites') }}</span>
                </RouterLink>
            </div>
            <div
                class="text-center p-4 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:hover:bg-gray-600 dark:bg-muted-700/10">
                <RouterLink :to="{ name: 'support' }">
                    <div
                        class="flex justify-center items-center p-2 mx-auto mb-2 bg-gray-200 dark:bg-muted-600/20 rounded-full w-[48px] h-[48px] max-w-[48px] max-h-[48px]">
                        <div class="transition duration-700 h-full w-full rounded-2xl flex items-center justify-center">
                            <i class="fa-light fa-headset text-primary"></i>
                        </div>
                    </div>
                    <span class="font-medium text-center text-gray-500 dark:text-gray-400">{{ $t('Support') }}</span>
                </RouterLink>
            </div>
        </div>
        <div class="pb-20"></div>
    </div>
    <aside :class="[sidebar === true ? '-translate-x-full' : '-translate-0']"
        class="fixed top-[60px] left-0 z-50 w-64 w-full-mobile transition-transform -translate-x-full sm:translate-x-0 sidebar-color custom-side-shadow"
        aria-label="Sidebar" style="height: -webkit-fill-available;">
        <div class="flex flex-col h-full p-5 pb-4 overflow-y-auto w-max">
            <ul class="space-y-2 font-medium">
                <li>
                    <RouterLink :to="{ name: 'home' }" active-class="link-active" class="flex items-center">
                        <div
                            class="side-btn transition duration-700 w-12 h-12 p-[1px] rounded-2xl flex items-center justify-center">
                            <div
                                class="side-link transition duration-700 h-full w-full rounded-2xl flex items-center justify-center">
                                <i class="fa-solid fa-house-chimney"></i>
                            </div>
                        </div>
                        <span
                            class="ml-3 transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-sm">{{
                            $t('Home') }}</span>
                    </RouterLink>
                </li>
                <li>
                    <RouterLink :to="{ name: 'profileWallet' }" active-class="link-active" class="flex items-center">
                        <div
                            class="side-btn transition duration-700 w-12 h-12 p-[1px] rounded-2xl flex items-center justify-center">
                            <div
                                class="side-link transition duration-700 h-full w-full rounded-2xl flex items-center justify-center">
                                <i class="fa-duotone fa-wallet"></i>
                            </div>
                        </div>
                        <span
                            class="ml-3 transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-sm">{{
                            $t('Wallet') }}</span>
                    </RouterLink>
                </li>
            </ul>
            <div class="categories mt-5 p-4 dark:bg-gray-900/20 border border-gray-800 rounded-lg shadow-lg">
                <div class="mb-5 flex items-center dark:text-[#94a3b8cc]">
                    <i class="fa-solid fa-grid-2"></i>
                    <h1 class="text-[12px] font-semibold ml-3 uppercase">Categorias</h1>
                </div>
                <ul class="space-y-3 font-medium">
                    <li v-for="(category, index) in categories" :key="index">
                        <RouterLink :to="{ name: 'casinosAll', params: { provider: 'all', category: category.slug } }"
                            active-class="link-active" class="flex items-center">
                            <div
                                class="side-btn transition duration-700 w-12 h-12 p-[1px] rounded-2xl flex items-center justify-center">
                                <div
                                    class="side-link transition duration-700 h-full w-full rounded-2xl flex items-center justify-center">
                                    <img :src="`/storage/` + category.image" alt="" width="20">
                                </div>
                            </div>
                            <span
                                class="ml-3 transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-sm">{{
                                $t(category.name) }}</span>
                        </RouterLink>
                    </li>
                </ul>
            </div>
            <div class="grow"></div>
            <ul class="mt-5 space-y-3">
                <li>
                    <RouterLink :to="{ name: 'casinos' }" active-class="link-active" class="flex items-center">
                        <div
                            class="side-btn transition duration-700 w-12 h-12 p-[1px] rounded-2xl flex items-center justify-center">
                            <div
                                class="side-link transition duration-700 h-full w-full rounded-2xl flex items-center justify-center">
                                <i class="fa-duotone fa-wallet"></i>
                            </div>
                        </div>
                        <span
                            class="ml-3 transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-sm">{{
                            $t('Favorites') }}</span>
                    </RouterLink>
                </li>
                <li>
                    <RouterLink :to="{ name: 'support' }" active-class="link-active"
                        class="link-active router-link-exact-active flex items-center">
                        <div
                            class="side-btn transition duration-700 w-12 h-12 p-[1px] rounded-2xl flex items-center justify-center">
                            <div
                                class="side-link transition duration-700 h-full w-full rounded-2xl flex items-center justify-center">
                                <i class="fa-light fa-headset text-primary"></i>
                            </div>
                        </div>
                        <span
                            class="ml-3 transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-sm">{{
                            $t('Support') }}</span>
                    </RouterLink>
                </li>
            </ul>
            <div class="flex items-center">
                <div class="flex gap-2 mt-5">
                    <!-- <div class="flex">
                        <a href="https://www.instagram.com/" target="_blank" class="text-md transition duration-700 hover:dark:text-white text-gray-500 p-3">
                            <i class="fa-brands fa-instagram"></i>
                        </a>
                    </div>
                -->
                    <div class="flex">
                        <a href="https://t.me/tribetbr" target="_blank"
                            class="text-md transition duration-700 hover:dark:text-white text-gray-500 p-3">
                            <i class="fa-brands fa-telegram"></i>
                        </a>
                    </div>
                    <!--<div class="flex">
                        <a href="https://www.instagram.com/" target="_blank" class="text-md transition duration-700 hover:dark:text-white text-gray-500 p-3">
                            <i class="fa-brands fa-whatsapp"></i>
                        </a>
                    </div>
                
                    <div class="flex">
                        <a href="https://www.instagram.com/" target="_blank" class="text-md transition duration-700 hover:dark:text-white text-gray-500 p-3">
                            <i class="fa-brands fa-facebook-f"></i>
                        </a>
                    </div>
                    
                    <div class="flex">
                        <a href="https://www.instagram.com/" target="_blank" class="text-md transition duration-700 hover:dark:text-white text-gray-500 p-3">
                            <i class="fa-brands fa-youtube"></i>
                        </a>
                    </div>
                -->
                </div>
            </div>
        </div>
    </aside>
</template>

<script>
import { onMounted } from "vue";
import { sidebarStore } from "@/Stores/SideBarStore.js";
import { RouterLink } from "vue-router";
import HttpApi from "@/Services/HttpApi.js";
import { useToast } from "vue-toastification";
import { useAuthStore } from "@/Stores/Auth.js";
import { useSettingStore } from "@/Stores/SettingStore.js";
import { missionStore } from "@/Stores/MissionStore.js";

export default {
    props: [],
    components: { RouterLink },
    data() {
        return {
            sidebar: false,
            isLoading: true,
            categories: [],
            sportsCategories: [],
            modalMission: null,
            setting: null,
        }
    },
    setup(props) {
        onMounted(() => {

        });

        return {};
    },
    computed: {
        sidebarMenuStore() {
            return sidebarStore()
        },
        sidebarMenu() {
            const sidebar = sidebarStore()
            return sidebar.getSidebarStatus;
        },
        isAuthenticated() {
            const authStore = useAuthStore();
            return authStore.isAuth;
        },
    },
    mounted() {
    },
    methods: {
        toggleMenu() {
            this.sidebarMenuStore.setSidebarToogle();
        },
        toggleMissionModal: function () {
            const missionDataStore = missionStore();
            missionDataStore.setMissionToogle();
        },
        getCasinoCategories: function () {
            const _this = this;
            const _toast = useToast();
            _this.isLoading = true;

            HttpApi.get('categories')
                .then(response => {
                    _this.categories = response.data.categories;
                    _this.isLoading = false;
                    
                })
                .catch(error => {
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        _toast.error(`${value}`);
                    });
                    _this.isLoading = false;
                });
        },
        getSetting: function () {
            const _this = this;
            const settingStore = useSettingStore();
            const settingData = settingStore.setting;

            if (settingData) {
                _this.setting = settingData;
            }
        },
    },
    created() {
        this.getCasinoCategories();
        this.getSetting();
    },
    watch: {
        sidebarMenu(newVal, oldVal) {
            this.sidebar = newVal;
        }
    },
};
</script>

<style scoped></style>
