<template>
    <BaseLayout :showFooter="false">
        <form class="lg:mb-0 md:mb-0 mb-28 relative p-4 w-full max-w-2xl max-h-full mx-auto">
            <fieldset class="relative">
                <legend class="mb-6">
                    <p class="nui-heading nui-heading-md nui-weight-medium nui-lead-none">Grupo para Contato</p>
                    <span class="nui-text nui-content-xs nui-weight-normal nui-lead-normal text-muted-400">Fale com um
                        de nossos especialistas</span>
                </legend>
                <!--
                <div class="grid grid-cols-12 gap-4 mb-3">
                    <div class="ltablet:col-span-6 col-span-12 lg:col-span-6">
                        <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="email">Name</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="nui-input" id="name" type="text" name="name" placeholder="Digite seu nome" required>
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon nui-input-icon-inner" width="1em" height="1em" viewBox="0 0 256 256" data-v-b4402e20=""><g fill="currentColor"><path d="M192 96a64 64 0 1 1-64-64a64 64 0 0 1 64 64" opacity=".2"></path><path d="M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8M72 96a56 56 0 1 1 56 56a56.06 56.06 0 0 1-56-56"></path></g></svg>
                                  	</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ltablet:col-span-6 col-span-12 lg:col-span-6">
                        <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="email">E-mail</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="nui-input" id="email" type="text" name="email" placeholder="Digite o E-mail" required>
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-regular fa-envelope text-success-emphasis"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-4 mb-3">
                    <div class="ltablet:col-span-6 col-span-12 lg:col-span-6">
                        <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="assunto">Assunto</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="nui-input" id="assunto" type="text" name="assunto" placeholder="Digite o assunto" required>
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-light fa-circle-info"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ltablet:col-span-6 col-span-12 lg:col-span-6">
                        <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                            <label class="nui-input-label" for="phone">Telefone</label>
                            <div class="nui-input-outer">
                                <div class="relative">
                                    <input class="nui-input" id="phone" name="phone" v-maska data-maska="[ '(##) ####-####', '(##) #####-####' ]" placeholder="Digite o telefone" required>
                                    <div class="icon-form absolute top-0 bottom-0 left-0">
                                        <i class="fa-regular fa-phone"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                        <label class="nui-input-label" for="message">Mensagem</label>
                        <div class="nui-input-outer">
                            <div class="relative">
                                <textarea id="message" class="!max-w-full !p-4 nui-input w-full" placeholder="Digite uma mensagem" rows="4" cols="2"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            -->

                <div class=" mt-3">
                    <div class="flex justify-center items-center h-full">
                        <img :src="'https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/121px-Telegram_2019_Logo.svg.png'"
                            alt="" class="h-100 w-100">
                    </div>
                    <div class="flex justify-center items-center h-full">
                        <a class="transition duration-700 text-white button-primary font-medium rounded-lg text-sm px-5 py-4 me-2 focus:outline-none w-1/3  text-center block"
                            href="https://t.me/tribetbr">
                            Entrar
                        </a>
                    </div>

                </div>
            </fieldset>
        </form>
    </BaseLayout>
</template>


<script>

import BaseLayout from "@/Layouts/BaseLayout.vue";

export default {
    props: [],
    components: { BaseLayout },
    data() {
        return {
            isLoading: false,
        }
    },
    setup(props) {


        return {};
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        redirectToWebsite() {
            window.location = 'https://t.me/tribetbr'; // Replace with your target URL
            //window.open('https://t.me/tribetbr', '_self');
        }

    },
    watch: {

    },
};
</script>

<style scoped></style>
