<template>
    <button v-if="!mobileHome" @click.prevent="toggleModalDeposit" type="button"
        :class="[showMobile === false ? 'hidden md:block' : 'block md:hidden', isFull ? 'w-full' : '', WalletPage === true ? 'transition duration-700 text-white button-primary font-medium rounded-lg text-sm px-5 py-2.5 me-2 focus:outline-none w-full' : 'items-center uppercase text-white btn-gradient font-medium rounded-3xl text-sm px-5 py-2.5 text-center']">
        {{ title }}
    </button>
    <button v-if="mobileHome" @click.prevent="toggleModalDeposit" type="button">
        <img class="w-full h-full" src="https://i.imgur.com/eu0mAnl.png">
        <span class="text-muted-500 text-[13.5px] relative" style="top: -4px;">Depósito</span>
    </button>
    <div id="modalElDeposit" tabindex="-1" aria-hidden="true"
        class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full z-[99999] hidden">
        <div class="md:max-w-[450px] bg-modal bg-login max-h-full rounded-[50px] shadow-2xl inline-table">
            <div class="w-screen md:w-[450px] p-[30px] maax-h-full">
                <div class="modal-header">
                    <div class="flex justify-between mb-4 h-[100px] relative left-0 right-0 border-b border-[#334155]">
                        <div class="flex items-center gap-4">
                            <div
                                class="nui-icon-box nui-box-md nui-box-pastel nui-box-success nui-mask nui-mask-blob items-center justify-center text-center flex rounded-lg">
                                <i class="fa-light fa-money-simple-from-bracket"></i>
                            </div>
                            <h1 class="dark:text-[#94a3b8cc] uppercase">Depósito</h1>
                        </div>
                        <div>
                            <img src="https://viperpro.casino/assets/images/carteira2.png" alt=""
                                class="absolute right-0 bottom-0 w-[150px] md:w-[200px]">
                            <!-- 
                            <button v-if="mobileHome" @click.prevent="toggleModalDeposit" class="absolute right-0 top-7 h-10 w-10 bg-black/50 rounded-lg">
                                <i class="fa-solid fa-xmark-large"></i>
                            </button>
							-->
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <DepositWidget />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useAuthStore } from "@/Stores/Auth.js";
import DepositWidget from "@/Components/Widgets/DepositWidget.vue";
import { onMounted } from "vue";
import { initFlowbite } from "flowbite";

export default {
    props: ['showMobile', 'title', 'isFull', 'WalletPage', 'mobileHome'],
    components: { DepositWidget },
    data() {
        return {
            isLoading: false,
            modalDeposit: undefined,
        }
    },
    setup(props) {
        onMounted(() => {
            initFlowbite();
        });

        return {};
    },
    computed: {
        isAuthenticated() {
            const authStore = useAuthStore();
            return authStore.isAuth;
        },
    },
    mounted() {
        const modalElement = document.getElementById('modalElDeposit');
        if (modalElement) {
            console.log("MakeDeposit: Modal E element exist");
            console.log(document.getElementById('modalElDeposit'));
            this.modalDeposit = new Modal(modalElement, {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-primary-opacity fixed inset-0 z-40',
                closable: true,
                onHide: () => {
                    this.paymentType = null;
                },
                onShow: () => { },
                onToggle: () => { },

            });

        } else {
            console.error('MakeDeposit: Element with ID "modalElDeposit" not found.');
            console.log(document.getElementById('modalElDeposit'));
        }
        /*
            this.modalDeposit = new Modal(document.getElementById('modalElDeposit'), {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-primary-opacity fixed inset-0 z-40',
                closable: true,
                onHide: () => {
                    this.paymentType =  undefined;
                },
                onShow: () => {

                },
                onToggle: () => {

                },
            });*/
    },
    beforeUnmount() {

    },
    methods: {
        toggleModalDeposit: function () {
            this.modalDeposit.toggle();
        },
    },
    created() {

    },
    watch: {

    },
};
</script>

<style scoped></style>
