<style scoped>
@import url('/resources/css/FooterComponent.css');
</style>

<template>
    <div class="mt-5 mb-16 p-4">
        <div class="grid grid-cols-3 md:grid-cols-4 gap-4">
            <div class="col-span-3 md:col-span-1 flex flex-col">
                <div class="flex justify-between mb-3">
                    <div class="mr-5">
                        <a v-if="setting" href="/" class="flex">
                            <img :src="`/storage/`+setting.software_logo_black" alt="" class="h-10 mr-3 block dark:hidden " />
                            <img :src="`/storage/`+setting.software_logo_white" alt=""  class="h-10 mr-3 hidden dark:block" />
                        </a>
                    </div>
                    <div>
                        <img src="/public/assets/images/+18.png" width="42" alt="">
                    </div>
                </div>
                <div>
                    <div class="text-[12px] text-muted-500">
                        {{ setting.software_description }}
                    </div>
                </div>
            </div>
            <div class="footer-list">
                <h3 class="text-base md:text-lg text-gray-600 dark:text-white font-bold">LINKS ÚTEIS</h3>
                <ul class="list-none mt-5">
                    <li class="transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-[12px]">
                        <a href="">{{ $t('Payment methods') }}</a>
                    </li>
                </ul>
            </div>
          <!--  <div class="footer-list">

                <h3 class="text-base md:text-lg text-gray-600 dark:text-white font-bold">SOBRE NÓS</h3>
                <ul class="list-none mt-5">
                    <li class="transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-[12px]">
                        <a @click="$router.push('/terms/service')" href="">Termos de Serviço</a>
                    </li>
                    <li class="transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-[12px]">
                        <a @click="$router.push('/terms/privacy-policy')" href="">Política de Privacidade</a>
                    </li>
                    <li class="transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-[12px]">
                        <a @click="$router.push('/terms/bonus')" href="">Termos de Bônus</a>
                    </li>
                </ul>
            </div>
        -->
            <div class="footer-list">
                <h3 class="text-base md:text-lg text-gray-600 dark:text-white font-bold">CONTACT</h3>
                <ul class="list-none mt-5">
                    <li class="transition duration-700 hover:dark:text-white text-gray-600 dark:text-gray-400 hover:text-gray-800 text-[12px]">
                        <a href="/support" class="">Send Us a Message</a>
                    </li>
                </ul>
            </div>
        </div>
        <hr class="border-t border-[#121822] dark:border-muted-800 mt-5">
        <div class="footer-logos">
            <ul class="flex justify-between items-center w-full">
                <li>
                    <a>
                        <img src="/public/assets/images/footer/01.svg" alt="">
                    </a>
                </li>
                <li>
                    <a>
                        <img src="/public/assets/images/footer/02.svg" alt="">
                    </a>
                </li>
                <li>
                    <a>
                        <img src="/public/assets/images/footer/03.svg" alt="">
                    </a>
                </li>
                <li>
                    <a>
                        <img src="/public/assets/images/footer/04.svg" alt="">
                    </a>
                </li>
                <li>
                    <a>
                        <img src="/public/assets/images/footer/05.svg" alt="">
                    </a>
                </li>
                <li>
                    <a>
                        <img src="/public/assets/images/footer/06.svg" alt="">
                    </a>
                </li>
                <li>
                    <a>
                        <img src="/public/assets/images/footer/07.svg" alt="">
                    </a>
                </li>
                <li>
                    <a>
                        <img src="/public/assets/images/footer/08.svg" alt="">
                    </a>
                </li>
            </ul>
        </div>
        <hr class="border-t border-muted-700 dark:border-muted-800 mt-5 hidden">
        <div class="mt-5 flex flex-col justify-center text-center">
            <p class="text-[12px] w-full text-muted-400">© {{ currentYear }} {{ setting.software_name }} Todos os direitos reservados. (Reg no. 157283)</p>
            <p class="text-[12px] text-muted-500">Zuikertuintjeweg Z/N (Zuikertuin Tower), Willemstad, Curaçao.</p>
        </div>
    </div>
</template>

<script>
    import HttpApi from "@/Services/HttpApi.js";
    import {useSettingStore} from "@/Stores/SettingStore.js";

    export default {
        props: [],
        components: {},
        data() {
            return {
                isLoading: false,
                year: new Date().getFullYear(),
                setting: null,
                custom: null,
                currentYear: new Date().getFullYear(),
            }
        },
        setup(props) {


            return {};
        },
        computed: {

        },
        mounted() {

        },
        methods: {
            getSetting: function() {
                const _this = this;
                const settingStore = useSettingStore();
                const settingData = settingStore.setting;

                if(settingData) {
                    _this.setting = settingData;
                }
            },
        },
        created() {
            this.getSetting();
            this.custom = custom;
        },
        watch: {

        },
    };
</script>

<style scoped>
    .footer-logos::-webkit-scrollbar {
        height: 2px;
    }
    .footer-logos::-webkit-scrollbar-thumb {
        background-color: #494949 !important;
        border-radius: 10px;
    }
  
    .footer-logos::-webkit-scrollbar-track {
        background: #19202e !important;
    }
</style>
