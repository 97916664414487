<template>
    <div class="block">
        <div v-if="(paymentType == null || paymentType === '') && wallet && setting">
            <div class="">
                <!-- Contador -->
                <!--
                @Dev @EmersonGjR
                <div class="mb-5 space-y-5 border-b border-[#334155] pb-4">

                    <div class="flex justify-between items-center">
                        <div class="text-lg uppercase">Bônus expira em</div>
                        <div class="flex gap-2 items-center">
                            <div
                                class="countdown-item w-12 p-3 rounded-lg text-white border border-[#334155] bg-transparent text-center">
                                {{ hours }}</div>
                            <div class="p-1 rounded-lg text-white bg-transparent">:</div>
                            <div
                                class="countdown-item w-12 p-3 rounded-lg text-white border border-[#334155] bg-transparent text-center">
                                {{ minutes }}</div>
                            <div class="p-1 rounded-lg text-white bg-transparent">:</div>
                            <div
                                class="countdown-item w-12 p-3 rounded-lg text-white border border-[#334155] bg-transparent text-center">
                                {{ seconds }}</div>
                        </div>
                    </div>
                </div>
            -->
                <ul class="mb-5 space-y-2 border-b border-[#334155] pb-4">
                    <li v-if="setting.digitopay_is_enable" @click="setPaymentMethod('pix', 'digitopay')"
                        class="p-1 bg-green-nui rounded-lg flex items-center gap-3">
                        <div
                            class="border-muted-200 dark:border-muted-700 flex size-10 items-center justify-center rounded-full border">
                            <img :src="`/assets/images/pix.png`" alt="" width="64">
                        </div>
                        <div>
                            <h4
                                class="nui-heading nui-heading-sm nui-weight-light nui-lead-tight text-muted-800 dark:text-white">
                                <span>Digito Pay</span>
                            </h4>
                            <p class="nui-paragraph nui-paragraph-xs nui-weight-normal nui-lead-normal">
                                <span class="text-muted-400"> Pagamento por Pix </span>
                            </p>
                        </div>
                        <div class="ms-auto flex items-center">
                            <button type="button" rel=""
                                class="nui-button-icon nui-button-rounded-lg nui-button-medium nui-button-default scale-75">
                                <i class="fa-regular fa-check text-green-500"></i>
                            </button>
                        </div>
                    </li>
                    <!-- Mercado Pago -->
                    <li v-if="setting.mercadopago_is_enable" @click="setPaymentMethod('pix', 'mercadopago')"
                        class="p-1 rounded-lg flex items-center gap-3">
                        <div
                            class="border-muted-200 dark:border-muted-700 flex size-10 items-center justify-center rounded-full border">
                            <img :src="`/assets/images/pix.png`" alt="" width="64">
                        </div>
                        <div>
                            <h4
                                class="nui-heading nui-heading-sm nui-weight-light nui-lead-tight text-muted-800 dark:text-white">
                                <span>Mercado Pago</span>
                            </h4>
                            <p class="nui-paragraph nui-paragraph-xs nui-weight-normal nui-lead-normal">
                                <span class="text-muted-400"> Pagamento por Pix </span>
                            </p>
                        </div>
                        <div class="ms-auto flex items-center">
                            <button type="button" rel=""
                                class="nui-button-icon nui-button-rounded-lg nui-button-medium nui-button-default scale-75">
                                <i class="fa-regular fa-check text-green-500"></i>
                            </button>
                        </div>
                    </li>
                    <!-- Sharkpay -->
                    <li v-if="setting.sharkpay_is_enable" @click="setPaymentMethod('pix', 'sharkpay')"
                        class="p-1 rounded-lg flex items-center gap-3">
                        <div
                            class="border-muted-200 dark:border-muted-700 flex size-10 items-center justify-center rounded-full border">
                            <img :src="`/assets/images/pix.png`" alt="" width="64">
                        </div>
                        <div>
                            <h4
                                class="nui-heading nui-heading-sm nui-weight-light nui-lead-tight text-muted-800 dark:text-white">
                                <span>Shark Pay</span>
                            </h4>
                            <p class="nui-paragraph nui-paragraph-xs nui-weight-normal nui-lead-normal">
                                <span class="text-muted-400"> Pagamento por Pix </span>
                            </p>
                        </div>
                        <div class="ms-auto flex items-center">
                            <button type="button" rel=""
                                class="nui-button-icon nui-button-rounded-lg nui-button-medium nui-button-default scale-75">
                                <i class="fa-regular fa-check text-green-500"></i>
                            </button>
                        </div>
                    </li>
                    <!-- Suitpay -->
                    <li v-if="setting.suitpay_is_enable" @click="setPaymentMethod('pix', 'suitpay')"
                        class="p-1 rounded-lg flex items-center gap-3">
                        <div
                            class="border-muted-200 dark:border-muted-700 flex size-10 items-center justify-center rounded-full border">
                            <img :src="`/assets/images/pix.png`" alt="" width="64">
                        </div>
                        <div>
                            <h4
                                class="nui-heading nui-heading-sm nui-weight-light nui-lead-tight text-muted-800 dark:text-white">
                                <span>SuitPay</span>
                            </h4>
                            <p class="nui-paragraph nui-paragraph-xs nui-weight-normal nui-lead-normal">
                                <span class="text-muted-400"> Pagamento por Pix </span>
                            </p>
                        </div>
                        <div class="ms-auto flex items-center">
                            <button type="button" rel=""
                                class="nui-button-icon nui-button-rounded-lg nui-button-medium nui-button-default scale-75">
                                <i class="fa-regular fa-check text-green-500"></i>
                            </button>
                        </div>
                    </li>
                    <!-- Stripe -->
                    <li v-if="setting.stripe_is_enable" @click="setPaymentMethod('stripe', 'stripe')"
                        class="p-1 rounded-lg flex items-center gap-3">
                        <div
                            class="border-muted-200 dark:border-muted-700 flex size-10 items-center justify-center rounded-full border">
                            <img :src="`/assets/images/stripe.png`" alt="" width="64">
                        </div>
                        <div>
                            <h4
                                class="nui-heading nui-heading-sm nui-weight-light nui-lead-tight text-muted-800 dark:text-white">
                                <span>Stripe</span>
                            </h4>
                            <p class="nui-paragraph nui-paragraph-xs nui-weight-normal nui-lead-normal">
                                <span class="text-muted-400 hidden"> Pagamento por Pix </span>
                            </p>
                        </div>
                        <div class="ms-auto flex items-center">
                            <button type="button" rel=""
                                class="nui-button-icon nui-button-rounded-lg nui-button-medium nui-button-default scale-75">
                                <i class="fa-regular fa-check text-green-500"></i>
                            </button>
                        </div>
                    </li>
                    <!-- PagoExpress -->
                    <li v-if="setting.pagoexpress_is_enable" @click="setPaymentMethod('pix', 'pagoexpress')"
                        class="p-1 rounded-lg flex items-center gap-3">
                        <div
                            class="border-muted-200 dark:border-muted-700 flex size-10 items-center justify-center rounded-full border">
                            <img :src="`/assets/images/pix.png`" alt="" width="64">
                        </div>
                        <div>
                            <h4
                                class="nui-heading nui-heading-sm nui-weight-light nui-lead-tight text-muted-800 dark:text-white">
                                <span>PagoExpress</span>
                            </h4>
                            <p class="nui-paragraph nui-paragraph-xs nui-weight-normal nui-lead-normal">
                                <span class="text-muted-400"> Pagamento por Pix </span>
                            </p>
                        </div>
                        <div class="ms-auto flex items-center">
                            <button type="button" rel=""
                                class="nui-button-icon nui-button-rounded-lg nui-button-medium nui-button-default scale-75">
                                <i class="fa-regular fa-check text-green-500"></i>
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Moeda pagamento -->
        <div v-if="paymentType === 'stripe' && publishableKey && setting && setting.stripe_is_enable" class="p-4">
            <stripe-checkout ref="checkoutRef" :pk="publishableKey" :sessionId="sessionId" />
            <div class="flex w-full mt-3 mb-3">
                <div class="w-36 mr-2">
                    <label for="currency" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{
                        $t('Currency') }}</label>
                    <select id="currency" v-model="currency"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="USD">$ {{ $t('Dollar') }}</option>
                        <option value="BRL">R$ {{ $t('Real') }}</option>
                    </select>
                </div>
                <div class="w-full">
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ $t('Amount')
                        }}</label>
                    <input type="number" v-model="amount"
                        class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        :min="setting.min_deposit" :max="setting.max_deposit" :placeholder="$t('0,00')" required>
                </div>
            </div>

            <button :disabled="!sessionId" @click.prevent="checkoutStripe" class="ui-button-blue rounded w-full">{{
                $t('Pay With Stripe') }}</button>
        </div>
        <!-- QrCode Pix -->
        <div
            v-if="setting && paymentType === 'pix' && (setting.suitpay_is_enable || setting.mercadopago_is_enable || setting.digitopay_is_enable || setting.pagoexpress_is_enable)">
            <div v-if="showPixQRCode && wallet" class="flex flex-col ">
                <div class="w-full p-4 bg-white dark:bg-gray-700 rounded mb-3">
                    <div class="flex justify-between">
                        <h2 class="text-base">Falta pouco! Escaneie o código QR pelo seu app de pagamentos ou Internet
                            Banking</h2>
                        <div class="text-4xl">
                            <i class="fa-regular fa-circle-dollar"></i>
                        </div>
                    </div>
                </div>

                <div class="w-full p-4">
                    <div>
                        <p class="font-bold">Valor do Pix a pagar: {{ state.currencyFormat(parseFloat(deposit.amount),
                            wallet.currency) }}</p>
                    </div>
                    <div class="p-3 flex justify-center items-center">
                        <QRCodeVue3 :value="qrcodecopypast" />
                    </div>
                    <div>
                        <p class="font-bold">Código válido por 23 horas.</p>
                    </div>
                    <div class="mt-4">
                        <p class="mb-3">Se preferir, você pode pagá-lo copiando e colando o código abaixo:</p>
                        <input id="pixcopiaecola" type="text" class="input" v-model="qrcodecopypast" readonly>
                    </div>

                    <div class="mt-5 w-full flex items-center justify-center">
                        <button @click.prevent="copyQRCode" type="button" class="ui-button-blue w-full">
                            <span class="uppercase font-semibold text-sm">{{ $t('Copy code') }}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="!showPixQRCode">
                <div v-if="setting != null && wallet != null && isLoading === false" class="flex flex-col w-full">
                    <form action="" @submit.prevent="submitQRCode">
                        <div class="mb-2">
                            <label class="text-muted-500 dark:text-muted-400 mb-2 block text-xs font-semibold">Valor a
                                ser depositado:</label>
                            <label class="hidden text-muted-300 dark:text-muted-400 mb-2 text-xs font-semibold">{{
                                state.currencyFormat(parseFloat(setting.min_deposit), wallet.currency) }} - {{
                                    state.currencyFormat(parseFloat(setting.max_deposit), wallet.currency) }}</label>
                            <div
                                class="nui-input-number-wrapper nui-input-number-default nui-input-number-md nui-input-rounded">
                                <div class="nui-input-number-outer">
                                    <div class="relative">
                                        <input type="text" v-model="deposit.amount" :min="setting.min_deposit"
                                            :max="setting.max_deposit" :placeholder="$t('Enter the value here')"
                                            class="nui-input-number appearance-none border border-gray-300 rounded-md bg-transparent border-none w-full"
                                            style="padding-top: 15px; padding-right: 25px; padding-bottom: 15px; padding-left: 40px !important; border-radius: 5px; border: 1px solid rgb(26 35 53);"
                                            required>
                                        <div class="nui-input-number-icon absolute left-0 top-0 bottom-0"
                                            style="height: 3.5rem;">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                                                class="icon nui-input-number-icon-inner" width="1em" height="1em"
                                                viewBox="0 0 24 24">
                                                <path fill="none" stroke="currentColor" stroke-linecap="round"
                                                    stroke-linejoin="round" stroke-width="2"
                                                    d="M21 6h-4a3 3 0 0 0 0 6h1a3 3 0 0 1 0 6h-4M4 18V6h3a3 3 0 1 1 0 6H4c5.5 0 5 4 6 6m8-12V4m-1 16v-2">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-3 text-gray-500">
                            <label class="text-muted-500 dark:text-muted-400 mb-2 block text-xs font-semibold">
                                {{ $t('Get an extra bonus') }} <span class="text-green-400 font-bold">{{
                                    setting.initial_bonus }}%</span> {{ $t('on a minimum deposit of') }} <span
                                    class="text-green-400 font-bold">{{
                                        state.currencyFormat(parseFloat(setting.min_deposit), wallet.currency) }}</span>
                            </label>
                        </div>

                        <div class="flex gap-2 items-start mt-3">
                            <div class="nui-checkbox-outer">
                                <input v-model="deposit.accept_bonus" type="checkbox"
                                    class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            </div>
                            <div class="nui-checkbox-label-wrapper">
                                <label for="ninja-input-10" class="nui-checkbox-label-text">
                                    <span
                                        class="text-muted-500 dark:text-muted-400 mb-2 block text-base font-semibold">Eu
                                        aceito o bônus entra</span>
                                </label>
                            </div>
                        </div>

                        <div class="mt-3 item-selected ">
                            <div @click.prevent="setAmount(parseFloat(setting.min_deposit))" class="item"
                                :class="{ 'active': selectedAmount === parseFloat(setting.min_deposit) }">
                                <button type="button">{{ state.currencyFormat(parseFloat(setting.min_deposit),
                                    wallet.currency) }}</button>
                                <div v-if="selectedAmount === parseFloat(setting.min_deposit)" class="ratio">+{{
                                    setting.initial_bonus }}%</div>
                                <img v-if="selectedAmount === parseFloat(setting.min_deposit)" class="img-check"
                                    :src="`/assets/images/check.webp`" alt="">
                            </div>
                            <div @click.prevent="setAmount(50.00)" class="item"
                                :class="{ 'active': selectedAmount === 50.00 }">
                                <button type="button">{{ wallet.symbol }} 50,00</button>
                                <div v-if="selectedAmount === 50.00" class="ratio">+{{ setting.initial_bonus }}%</div>
                                <img v-if="selectedAmount === 50.00" class="img-check"
                                    :src="`/assets/images/check.webp`" alt="">
                            </div>
                            <div @click.prevent="setAmount(200.00)" class="item"
                                :class="{ 'active': selectedAmount === 200.00 }">
                                <button type="button">{{ wallet.symbol }} 200,00</button>
                                <div v-if="selectedAmount === 200.00" class="ratio">+{{ setting.initial_bonus }}%</div>
                                <img v-if="selectedAmount === 200.00" class="img-check"
                                    :src="`/assets/images/check.webp`" alt="">
                            </div>
                        </div>

                        <div
                            class="mt-5 nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon">
                            <label class="nui-input-label" for="ninja-input-cpf">CPF/CNPJ</label>
                            <div class="nui-input-outer">
                                <div class="absolute right-4 top-0 z-[1]">
                                    <div class="h-14 flex items-center justify-center z-[1]"></div>
                                </div>
                                <div class="relative">
                                    <input class="nui-input" id="ninja-input-cpf" type="text" v-model="deposit.cpf"
                                        max="18" v-maska data-maska="[
                                    '###.###.###-##',
                                    '##.###.###/####-##'
                                   ]" placeholder="Digite o CPF" style="padding-left:40px;">
                                    <div class="icon-form absolute left-0 top-0 bottom-0">
                                        <svg data-v-a8eacbc2="" xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                                            class="icon nui-input-number-icon-inner" width="1em" height="1em"
                                            viewBox="0 0 256 256">
                                            <g fill="currentColor">
                                                <path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96" opacity=".2">
                                                </path>
                                                <path
                                                    d="M72 128a134.63 134.63 0 0 1-14.16 60.47a8 8 0 1 1-14.32-7.12A118.8 118.8 0 0 0 56 128a71.73 71.73 0 0 1 27-56.2a8 8 0 1 1 10 12.49A55.76 55.76 0 0 0 72 128m56-8a8 8 0 0 0-8 8a184.12 184.12 0 0 1-23 89.1a8 8 0 0 0 14 7.76A200.19 200.19 0 0 0 136 128a8 8 0 0 0-8-8m0-32a40 40 0 0 0-40 40a8 8 0 0 0 16 0a24 24 0 0 1 48 0a214.09 214.09 0 0 1-20.51 92a8 8 0 1 0 14.51 6.83A230 230 0 0 0 168 128a40 40 0 0 0-40-40m0-64A104.11 104.11 0 0 0 24 128a87.76 87.76 0 0 1-5 29.33a8 8 0 0 0 15.09 5.33A103.9 103.9 0 0 0 40 128a88 88 0 0 1 176 0a282.24 282.24 0 0 1-5.29 54.45a8 8 0 0 0 6.3 9.4a8.22 8.22 0 0 0 1.55.15a8 8 0 0 0 7.84-6.45A298.37 298.37 0 0 0 232 128A104.12 104.12 0 0 0 128 24M94.4 152.17a8 8 0 0 0-9.4 6.25a151 151 0 0 1-17.21 45.44a8 8 0 0 0 13.86 8a166.67 166.67 0 0 0 19-50.25a8 8 0 0 0-6.25-9.44M128 56a72.85 72.85 0 0 0-9 .56a8 8 0 0 0 2 15.87A56.08 56.08 0 0 1 184 128a252.12 252.12 0 0 1-1.92 31a8 8 0 0 0 6.92 9a8.39 8.39 0 0 0 1 .06a8 8 0 0 0 7.92-7a266.48 266.48 0 0 0 2-33A72.08 72.08 0 0 0 128 56m57.93 128.25a8 8 0 0 0-9.75 5.75c-1.46 5.69-3.15 11.4-5 17a8 8 0 0 0 5 10.13a7.88 7.88 0 0 0 2.55.42a8 8 0 0 0 7.58-5.46c2-5.92 3.79-12 5.35-18.05a8 8 0 0 0-5.72-9.78Z">
                                                </path>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="py-8 pt-4 w-full">
                            <div class="flex lg:flex-row flex-col w-full gap-2">
                                <button
                                    class="hidden p-4 nui-button-default border border-[#334155] nui-button nui-button-large nui-button-rounded nui-button-solid w-full text-sm font-extrabold rounded-lg border-primary"
                                    type="button">Voltar</button>
                                <button
                                    class="p-4 btn-gradient nui-button nui-button-large nui-button-rounded nui-button-solid w-full text-sm font-extrabold rounded-lg border-primary"
                                    type="submit">{{ $t('Deposit') }}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div v-if="isLoading" role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor" />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill" />
                    </svg>
                    <span class="sr-only">{{ $t('Loading') }}...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import HttpApi from "@/Services/HttpApi.js";
import QRCodeVue3 from "qrcode-vue3";
import { useAuthStore } from "@/Stores/Auth.js";
import { useSettingStore } from "@/Stores/SettingStore.js";

export default {
    props: ['showMobile', 'title', 'isFull'],
    components: { QRCodeVue3 },
    data() {
        return {
            isLoading: false,
            minutes: 15,
            seconds: 0,
            timer: null,
            setting: null,
            wallet: null,
            pago_express_valid_until: 0,
            deposit: {
                amount: '',
                cpf: '',
                gateway: '',
                accept_bonus: true
            },
            selectedAmount: 0,
            showPixQRCode: false,
            qrcodecopypast: '',
            idTransaction: '',
            intervalId: null,
            paymentType: null,
            elementsOptions: {
                appearance: {}, // appearance options
            },
            confirmParams: {
                return_url: null, // success url
            },
            successURL: null,
            cancelURL: null,
            amount: null,
            currency: null,
            publishableKey: null,
            sessionId: null,
            paymentGateway: '',
            payed: false,
            //debug @dev @EmersonGjR
            modalDeposit: null
        }
    },
    setup(props) {


        return {};
    },
    computed: {
        isAuthenticated() {
            const authStore = useAuthStore();
            return authStore.isAuth;
        },
    },
    mounted() {
        const modalElement = document.getElementById('modalElDeposit');
        if (modalElement) {
            console.log("Modal E element exist");
            console.log(document.getElementById('modalElDeposit'));
            this.modalDeposit = new Modal(modalElement, {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-primary-opacity fixed inset-0 z-40',
                closable: true,
                onHide: () => {
                    this.paymentType = null;
                },
                onShow: () => { },
                onToggle: () => { },

            });

        } else {
            console.error('Element with ID "modalElDeposit" not found.');
            console.log(document.getElementById('modalElDeposit'));
        }
        /* this.modalDeposit = new Modal(document.getElementById('modalElDeposit'), {
             placement: 'center',
             backdrop: 'dynamic',
             backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
             closable: true,
             onHide: () => {
                 this.paymentType = undefined;
             },
             onShow: () => {
 
             },
             onToggle: () => {
 
             },
         });*/
    },
    beforeUnmount() {
        clearInterval(this.timer);
        this.paymentType = null;
    },
    methods: {
        setPaymentMethod: function (type, gateway) {
            this.paymentType = type;
            this.paymentGateway = gateway;
        },
        openModalDeposit: function () {
            this.modalDeposit.toggle();
        },
        submitQRCode: function (event) {
            const _this = this;
            const _toast = useToast();
            if (_this.deposit.amount === '' || _this.deposit.amount === undefined) {
                _toast.error(_this.$t('You need to enter a value'));
                return;
            }

            if (_this.deposit.cpf === '' || _this.deposit.cpf === undefined) {
                _toast.error(_this.$t('Do you need to enter your CPF or CNPJ'));
                return;
            }

            if (parseFloat(_this.deposit.amount) < parseFloat(_this.setting.min_deposit)) {
                _toast.error('O valor mínimo de depósito é de ' + _this.setting.min_deposit);
                return;
            }

            if (parseFloat(_this.deposit.amount) > parseFloat(_this.setting.max_deposit)) {
                _toast.error('O valor máximo de depósito é de ' + _this.setting.min_deposit);
                return;
            }

            _this.deposit.paymentType = _this.paymentType;
            _this.deposit.gateway = _this.paymentGateway;

            _this.isLoading = true;
            HttpApi.post('wallet/deposit/payment', _this.deposit).then(response => {
                const resp = response.data;
                if (resp.status) {
                    _this.showPixQRCode = true;
                    _this.isLoading = false;

                    _this.idTransaction = response.data.idTransaction;
                    _this.qrcodecopypast = response.data.qrcode;

                    _this.intervalId = setInterval(function () {
                        _this.checkTransactions(_this.idTransaction);
                    }, 5000);
                } else {
                    Object.entries(resp.error).forEach(([key, value]) => {
                        _toast.error(`${value}`);
                    });
                    _this.showPixQRCode = false;
                    _this.isLoading = false;
                }
            }).catch(error => {
                console.log(error);
                Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                    _toast.error(`${value}`);
                });
                _this.showPixQRCode = false;
                _this.isLoading = false;
            });
        },
        checkTransactions: function (idTransaction) {
            const _this = this;
            const _toast = useToast();

            HttpApi.post(_this.paymentGateway + '/consult-status-transaction', { idTransaction }).then(response => {
                const resp = response.data;
                console.log(resp);
                if (resp.status) {
                    _this.payed = true;
                    _toast.success('Pedido concluído com sucesso');
                    clearInterval(_this.intervalId);
                }
            }).catch(error => {
                Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                    // _toast.error(`${value}`);
                });
            });
        },
        copyQRCode: function (event) {
            const _toast = useToast();
            var inputElement = document.getElementById("pixcopiaecola");
            inputElement.select();
            inputElement.setSelectionRange(0, 99999);  // Para dispositivos móveis

            // Copia o conteúdo para a área de transferência
            document.execCommand("copy");
            _toast.success('Pix Copiado com sucesso');
        },
        setAmount: function (amount) {
            this.deposit.amount = amount;
            this.selectedAmount = amount;
        },
        getWallet: function () {
            const _this = this;
            const _toast = useToast();
            _this.isLoadingWallet = true;

            HttpApi.get('profile/wallet')
                .then(response => {
                    _this.wallet = response.data.wallet;
                    _this.currency = response.data.wallet.currency;
                    _this.isLoadingWallet = false;
                })
                .catch(error => {
                    const _this = this;
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        _toast.error(`${value}`);
                    });
                    _this.isLoadingWallet = false;
                });
        },
        getSetting: function () {
            const _this = this;
            const settingStore = useSettingStore();
            const settingData = settingStore.setting;

            if (settingData) {
                _this.setting = settingData;
                _this.amount = settingData.max_deposit;

                _this.pago_express_valid_until = settingData.pago_express_valid_until;
            }
        },
        ConverterMinToHMs(m) {
            if (m >= 60) {
                var h = Math.floor(m / 60);
                var mRest = m % 60;
                if (mRest === 0) {
                    return h + "h";
                } else {
                    return h + "h e " + mRest + " minutos";
                }
            } else {
                return m + " minutos";
            }
        }
    },
    created() {
        if (this.isAuthenticated) {
            this.getWallet();
            this.getSetting();
        }
    }
};
</script>

<style scoped>
.countdown-item {
    animation: countdown-fade 1s infinite;
    transition: transform 0.2s ease-in-out;
}

.countdown-item:hover {
    transform: scale(1.1);
}

@keyframes countdown-fade {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}
</style>
