<template>
    <BaseLayout :showFooter="false">
        <LoadingComponent :isLoading="isLoading"></LoadingComponent>
        <div v-if="!isLoading && setting != null && wallet != null" class="mx-auto mt-20">
            <div class="grid grid-cols-1 md:grid-cols-3">
                <div>
                    <div class="hidden md:block">
                        <div class="flex flex-col h-[calc(100vh-150px)] p-5">
                            <div class="relative bg-card flex flex-col justify-center items-center rounded-lg px-5 py-16 col-span-1">
                                <div @click="toggleVisibility" class="absolute right-5 top-5 toggle-visible cursor-pointer">
                                    <i v-if="isHidden" class="fas fa-eye-slash"></i>
                                    <i v-else class="far fa-eye"></i>
                                </div>
                                <div class="mt-5 text-center">
                                    <p class="text-md text-muted-400">Valor disponível</p>
                                    <div>
                                        <h1 class="text-4xl font-bold text-white">{{ isHidden ? '*****' : state.currencyFormat(parseFloat(wallet.balance_withdrawal), wallet.currency) }}</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="relative bg-card flex items-center px-3 py-2 mt-4 rounded-lg w-full cursor-pointer">
                                <div class="flex justify-between items-center gap-4 w-full">
                                    <div class="flex items-center w-full">
                                        <div class="mr-3 text-muted-500">
                                            <i class="fa-light fa-square-dollar text-4xl"></i>
                                        </div>
                                        <div class="block leading-[0.5rem]">
                                            <p class="text-[12px] text-gray-500">Saldo total</p>
                                            <h1 class="text-lg font-bold text-white">{{ isHidden ? '*****' : wallet.symbol + ' ' + wallet.total_balance }}</h1>
                                        </div>
                                    </div>
                                    <div class="flex-grow"></div>
                                    <div>
                                        <i class="fa-regular fa-angle-right text-2xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative col-span-2 lg:pr-4 lg:px-0 px-3">
                    <div class="flex flex-col w-full">
                        <div class="mt-5 flex flex-col bg-card p-4 rounded-lg">
                            <div class="flex items-center mb-5">
                                <div>
                                    <h1 class="text-lg md:text-2xl text-muted-400 font-semibold">Informações da Carteira</h1>
                                    <p class="text-muted-500 text-sm md:text-sm">Abaixo você pode ver todas as informações da sua carteira e também ativar sua carteira principal.</p>
                                </div>
                            </div>
                            <form @submit.prevent="submitWithdrawBank">
                                <div class="mb-2">
                                   <i class="fa-regular fa-building-columns text-lg mr-3"></i>
                                   <span class="ml-3">{{ $t('BANK') }}</span>
                               </div>
                               <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                                    <label class="nui-input-label" for="depositName">Nome do titular da conta</label>
                                    <div class="nui-input-outer">
                                        <div class="relative">
                                            <input class="!max-w-full w-full nui-input" id="depositName" type="text" v-model="withdraw_deposit.name" placeholder="Digite o nome do titular da conta" required>
                                            <div class="icon-form absolute top-0 bottom-0 left-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon nui-input-icon-inner" width="1em" height="1em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96" opacity=".2"></path><path d="M72 128a134.63 134.63 0 0 1-14.16 60.47a8 8 0 1 1-14.32-7.12A118.8 118.8 0 0 0 56 128a71.73 71.73 0 0 1 27-56.2a8 8 0 1 1 10 12.49A55.76 55.76 0 0 0 72 128m56-8a8 8 0 0 0-8 8a184.12 184.12 0 0 1-23 89.1a8 8 0 0 0 14 7.76A200.19 200.19 0 0 0 136 128a8 8 0 0 0-8-8m0-32a40 40 0 0 0-40 40a8 8 0 0 0 16 0a24 24 0 0 1 48 0a214.09 214.09 0 0 1-20.51 92a8 8 0 1 0 14.51 6.83A230 230 0 0 0 168 128a40 40 0 0 0-40-40m0-64A104.11 104.11 0 0 0 24 128a87.76 87.76 0 0 1-5 29.33a8 8 0 0 0 15.09 5.33A103.9 103.9 0 0 0 40 128a88 88 0 0 1 176 0a282.24 282.24 0 0 1-5.29 54.45a8 8 0 0 0 6.3 9.4a8.22 8.22 0 0 0 1.55.15a8 8 0 0 0 7.84-6.45A298.37 298.37 0 0 0 232 128A104.12 104.12 0 0 0 128 24M94.4 152.17a8 8 0 0 0-9.4 6.25a151 151 0 0 1-17.21 45.44a8 8 0 0 0 13.86 8a166.67 166.67 0 0 0 19-50.25a8 8 0 0 0-6.25-9.44M128 56a72.85 72.85 0 0 0-9 .56a8 8 0 0 0 2 15.87A56.08 56.08 0 0 1 184 128a252.12 252.12 0 0 1-1.92 31a8 8 0 0 0 6.92 9a8.39 8.39 0 0 0 1 .06a8 8 0 0 0 7.92-7a266.48 266.48 0 0 0 2-33A72.08 72.08 0 0 0 128 56m57.93 128.25a8 8 0 0 0-9.75 5.75c-1.46 5.69-3.15 11.4-5 17a8 8 0 0 0 5 10.13a7.88 7.88 0 0 0 2.55.42a8 8 0 0 0 7.58-5.46c2-5.92 3.79-12 5.35-18.05a8 8 0 0 0-5.72-9.78Z"></path></g></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- -->
                                <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                                    <label class="nui-input-label" for="depositPix">Chave Pix</label>
                                    <div class="nui-input-outer">
                                        <div class="relative">
                                            <input class="!max-w-full w-full nui-input" id="depositPix" type="text" v-model="withdraw.pix_key" placeholder="Digite a sua Chave pix" required>
                                            <div class="icon-form absolute top-0 bottom-0 left-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon nui-input-icon-inner" width="1em" height="1em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96" opacity=".2"></path><path d="M72 128a134.63 134.63 0 0 1-14.16 60.47a8 8 0 1 1-14.32-7.12A118.8 118.8 0 0 0 56 128a71.73 71.73 0 0 1 27-56.2a8 8 0 1 1 10 12.49A55.76 55.76 0 0 0 72 128m56-8a8 8 0 0 0-8 8a184.12 184.12 0 0 1-23 89.1a8 8 0 0 0 14 7.76A200.19 200.19 0 0 0 136 128a8 8 0 0 0-8-8m0-32a40 40 0 0 0-40 40a8 8 0 0 0 16 0a24 24 0 0 1 48 0a214.09 214.09 0 0 1-20.51 92a8 8 0 1 0 14.51 6.83A230 230 0 0 0 168 128a40 40 0 0 0-40-40m0-64A104.11 104.11 0 0 0 24 128a87.76 87.76 0 0 1-5 29.33a8 8 0 0 0 15.09 5.33A103.9 103.9 0 0 0 40 128a88 88 0 0 1 176 0a282.24 282.24 0 0 1-5.29 54.45a8 8 0 0 0 6.3 9.4a8.22 8.22 0 0 0 1.55.15a8 8 0 0 0 7.84-6.45A298.37 298.37 0 0 0 232 128A104.12 104.12 0 0 0 128 24M94.4 152.17a8 8 0 0 0-9.4 6.25a151 151 0 0 1-17.21 45.44a8 8 0 0 0 13.86 8a166.67 166.67 0 0 0 19-50.25a8 8 0 0 0-6.25-9.44M128 56a72.85 72.85 0 0 0-9 .56a8 8 0 0 0 2 15.87A56.08 56.08 0 0 1 184 128a252.12 252.12 0 0 1-1.92 31a8 8 0 0 0 6.92 9a8.39 8.39 0 0 0 1 .06a8 8 0 0 0 7.92-7a266.48 266.48 0 0 0 2-33A72.08 72.08 0 0 0 128 56m57.93 128.25a8 8 0 0 0-9.75 5.75c-1.46 5.69-3.15 11.4-5 17a8 8 0 0 0 5 10.13a7.88 7.88 0 0 0 2.55.42a8 8 0 0 0 7.58-5.46c2-5.92 3.79-12 5.35-18.05a8 8 0 0 0-5.72-9.78Z"></path></g></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- -->
                                <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                                    <label class="nui-input-label" for="typeChave">Tipo de Chave</label>
                                    <div class="nui-input-outer">
                                        <div class="relative">
                                            <select class="!max-w-full w-full nui-input" v-model="withdraw.pix_type" id="typeChave" name="type_document" required>
                                                <option value="">Selecione uma chave</option>
                                                <option value="document">CPF/CNPJ</option>
                                                <option value="email">E-mail</option>
                                                <option value="phoneNumber">Telefone</option>
                                                <option value="randomKey">Chave Aleatória</option>
                                            </select>
                                            <div class="icon-form absolute top-0 bottom-0 left-0">
                                                <i class="fa-regular fa-key"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- -->
                                <div class="nui-input-wrapper nui-input-default nui-input-md nui-input-rounded-sm nui-has-icon mb-2">
                                    <div class="flex justify-between mb-1">
                                        <label class="nui-input-label" for="depositValue">Valor ({{ setting.min_withdrawal }} ~ {{ setting.max_withdrawal }})</label>
                                        <label class="nui-input-label" for="depositValue">Saldo: {{ state.currencyFormat(parseFloat(wallet.balance_withdrawal), wallet.currency) }}</label>
                                    </div>
                                    <div class="nui-input-outer">
                                        <div class="relative">
                                            <input class="!max-w-full w-full nui-input" id="depositValue" type="text" v-model="withdraw.amount" :min="setting.min_withdrawal" :max="setting.max_withdrawal" required>
                                            <div class="icon-form absolute top-0 bottom-0 left-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="icon nui-input-icon-inner" width="1em" height="1em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M224 128a96 96 0 1 1-96-96a96 96 0 0 1 96 96" opacity=".2"></path><path d="M72 128a134.63 134.63 0 0 1-14.16 60.47a8 8 0 1 1-14.32-7.12A118.8 118.8 0 0 0 56 128a71.73 71.73 0 0 1 27-56.2a8 8 0 1 1 10 12.49A55.76 55.76 0 0 0 72 128m56-8a8 8 0 0 0-8 8a184.12 184.12 0 0 1-23 89.1a8 8 0 0 0 14 7.76A200.19 200.19 0 0 0 136 128a8 8 0 0 0-8-8m0-32a40 40 0 0 0-40 40a8 8 0 0 0 16 0a24 24 0 0 1 48 0a214.09 214.09 0 0 1-20.51 92a8 8 0 1 0 14.51 6.83A230 230 0 0 0 168 128a40 40 0 0 0-40-40m0-64A104.11 104.11 0 0 0 24 128a87.76 87.76 0 0 1-5 29.33a8 8 0 0 0 15.09 5.33A103.9 103.9 0 0 0 40 128a88 88 0 0 1 176 0a282.24 282.24 0 0 1-5.29 54.45a8 8 0 0 0 6.3 9.4a8.22 8.22 0 0 0 1.55.15a8 8 0 0 0 7.84-6.45A298.37 298.37 0 0 0 232 128A104.12 104.12 0 0 0 128 24M94.4 152.17a8 8 0 0 0-9.4 6.25a151 151 0 0 1-17.21 45.44a8 8 0 0 0 13.86 8a166.67 166.67 0 0 0 19-50.25a8 8 0 0 0-6.25-9.44M128 56a72.85 72.85 0 0 0-9 .56a8 8 0 0 0 2 15.87A56.08 56.08 0 0 1 184 128a252.12 252.12 0 0 1-1.92 31a8 8 0 0 0 6.92 9a8.39 8.39 0 0 0 1 .06a8 8 0 0 0 7.92-7a266.48 266.48 0 0 0 2-33A72.08 72.08 0 0 0 128 56m57.93 128.25a8 8 0 0 0-9.75 5.75c-1.46 5.69-3.15 11.4-5 17a8 8 0 0 0 5 10.13a7.88 7.88 0 0 0 2.55.42a8 8 0 0 0 7.58-5.46c2-5.92 3.79-12 5.35-18.05a8 8 0 0 0-5.72-9.78Z"></path></g></svg>
                                            </div>
                                            <div class="absolute flex items-center pr-1 right-0 top-0 bottom-0">
                                                <div class="inline-flex shadow-sm gap-1" role="group">
                                                    <button @click.prevent="setMinAmount" type="button" class="px-4 py-2 text-sm font-medium border border-muted-0029 rounded-lg">
                                                        min
                                                    </button>
                                                    <button @click.prevent="setPercentAmount(50)" type="button" class="px-4 py-2 text-sm font-medium border border-muted-0029 rounded-lg">
                                                        50%
                                                    </button>
                                                    <button @click.prevent="setPercentAmount(100)" type="button" class="px-4 py-2 text-sm font-medium border border-muted-0029 rounded-lg">
                                                        100%
                                                    </button>
                                                    <button @click.prevent="setMaxAmount" type="button" class="px-4 py-2 text-sm font-medium border border-muted-0029 rounded-lg">
                                                        max
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between mt-2">
                                        <p class="nui-input-label">{{ $t('Available') }}: {{ state.currencyFormat(parseFloat(wallet.balance_withdrawal), wallet.currency) }} {{ wallet.currency }}</p>
                                        <p class="nui-input-label">{{ $t('Balance Rollback') }}: {{ state.currencyFormat(parseFloat(wallet.balance_bonus), wallet.currency) }} {{ wallet.currency }}</p>
                                    </div>
                                </div>
                                <div class="mt-3 w-full mb-2">
                                    <button class="transition duration-700 text-white button-primary font-medium rounded-lg text-sm px-5 py-2.5 me-2 focus:outline-none w-full" type="submit">
                                        {{ $t('Request withdrawal') }}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
import { useRouter } from "vue-router";
import BaseLayout from "@/Layouts/BaseLayout.vue";
import HttpApi from "@/Services/HttpApi.js";
import { useToast } from "vue-toastification";
import { useSettingStore } from "@/Stores/SettingStore.js";

export default {
    components: { BaseLayout },
    data() {
        return {
            isLoading: false,
            setting: null,
            wallet: null,
            withdraw: {
                name: '',
                pix_key: '',
                pix_type: '',
                amount: '',
                type: 'pix',
                currency: '',
                symbol: '',
            },
            withdraw_deposit: {
                name: '',
                bank_info: '',
                amount: '',
                type: 'bank',
                currency: '',
                symbol: '',
            },
        };
    },
    setup() {
        const router = useRouter();
        return { router };
    },
    methods: {
        setMinAmount() {
            this.withdraw.amount = this.setting.min_withdrawal;
        },
        setMaxAmount() {
            this.withdraw.amount = this.setting.max_withdrawal;
        },
        setPercentAmount(percent) {
            this.withdraw.amount = (percent / 100) * this.wallet.balance_withdrawal;
        },
        getWallet() {
            const _this = this;
            const _toast = useToast();
            this.isLoading = true;

            HttpApi.get('profile/wallet')
                .then(response => {
                    _this.wallet = response.data.wallet;
                    _this.withdraw.currency = response.data.wallet.currency;
                    _this.withdraw.symbol = response.data.wallet.symbol;
                    _this.withdraw_deposit.currency = response.data.wallet.currency;
                    _this.withdraw_deposit.symbol = response.data.wallet.symbol;
                    _this.isLoading = false;
                })
                .catch(error => {
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        _toast.error(`${value}`);
                    });
                    _this.isLoading = false;
                });
        },
        getSetting() {
            const settingStore = useSettingStore();
            const settingData = settingStore.setting;
            if (settingData) {
                this.setting = settingData;
                this.withdraw.amount = settingData.min_withdrawal;
                this.withdraw_deposit.amount = settingData.min_withdrawal;
            }
            this.isLoading = false;
        },
        submitWithdrawBank() {
            const _this = this;
            const _toast = useToast();
            this.isLoading = true;

            HttpApi.post('wallet/withdraw/request', _this.withdraw_deposit)
                .then(response => {
                    _this.isLoading = false;
                    _this.withdraw_deposit = {
                        name: '',
                        bank_info: '',
                        amount: '',
                        type: 'bank',
                        currency: '',
                        symbol: '',
                    };
                    _this.router.push({ name: 'profileTransactions' });
                    _toast.success(response.data.message);
                })
                .catch(error => {
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        _toast.error(`${value}`);
                    });
                    _this.isLoading = false;
                });
        },
        submitWithdraw() {
            const _this = this;
            const _toast = useToast();
            this.isLoading = true;

            HttpApi.post('wallet/withdraw/request', _this.withdraw)
                .then(response => {
                    _this.isLoading = false;
                    _this.withdraw = {
                        name: '',
                        pix_key: '',
                        pix_type: '',
                        amount: '',
                        type: 'pix',
                        currency: '',
                        symbol: '',
                    };
                    _this.router.push({ name: 'profileTransactions' });
                    _toast.success(response.data.message);
                })
                .catch(error => {
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        _toast.error(`${value}`);
                    });
                    _this.isLoading = false;
                });
        },
    },
    created() {
        this.getWallet();
        this.getSetting();
    },
};
</script>

<style scoped>

</style>
