<style scoped>
@import url('/resources/css/NavBottomComponent.css');
</style>


<template>
<div class="flex sm:hidden">
	<div class="fixed z-40 w-full h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 bottom-0 left-1/2 dark:bg-gray-800 dark:border-gray-800">
		<div class="grid h-full max-w-lg grid-cols-5 mx-auto">
			<div>
				<button @click.prevent="toggleMenu" type="button" class="mt-1 text-muted-500 inline-flex flex-col items-center justify-center px-5 rounded-l-full hover:bg-gray-50 dark:hover:bg-gray-800 group">
                  <i class="fa-regular fa-bars-sort text-xl"></i>
                  <!---->
                  <span class="text-muted-500 text-[12px] relative" style="top: 4px;">Menu</span>
				</button>
			</div>
          <div>
				<button @click="$router.push('/support')" type="button" class="mt-1 text-muted-500 inline-flex flex-col items-center justify-center px-5 rounded-l-full hover:bg-gray-50 dark:hover:bg-gray-800 group">
                  <i class="fa-regular fa-headset text-xl"></i>
                  <!---->
                  <span class="text-muted-500 text-[12px] relative" style="top: 4px;">{{ $t('Support') }}</span>
				</button>
			</div>
          <div>
				<button type="button" class="absolute -top-6 mx-auto flex size-[85px] scale-90 items-center justify-center transition-all duration-300 group-hover:-translate-y-1 group-hover:scale-90">
                  <div class="flex flex-col items-center justify-center">
                    <MakeDeposit :showMobile="true" :mobileHome="true" :title="'Depósito'" :WalletPage="false" class="text-muted-500 text-[13px] relative">
                    </MakeDeposit>
                  </div>
				</button>
			</div>
          <div>
				<button @click="$router.push('/casino')" type="button" class="mt-1 text-muted-500 inline-flex flex-col items-center justify-center px-5 rounded-l-full hover:bg-gray-50 dark:hover:bg-gray-800 group">
                  <i class="text-muted-500 fa-duotone fa-dice mb-1 text-xl"></i>
                  <!---->
                  <span class="text-muted-500 text-[12px]">{{ $t('Casino') }}</span>
				</button>
			</div>
          <div @click="$router.push('/profile/wallet')">
				<button type="button" class="mt-1 text-muted-500 inline-flex flex-col items-center justify-center px-5 rounded-l-full hover:bg-gray-50 dark:hover:bg-gray-800 group">
                  <i class="text-muted-500 fa-duotone fa-wallet mb-1 text-xl"></i>
                  <!---->
                  <span class="text-muted-500 text-[12px]">{{ $t('Wallet') }}</span>
				</button>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { sidebarStore } from "@/Stores/SideBarStore.js";
import MakeDeposit from "@/Components/UI/MakeDeposit.vue";
export default {
    props: ['simple'],
    components: { MakeDeposit },
    data() {
        return {
            isLoading: false,
          	showMenu: false,
        }
    },
    setup(props) {


        return {};
    },
    computed: {
      sidebarMenuStore() {
            return sidebarStore();
        },
    },
    mounted() {

    },
    methods: {
      toggleMenu: function() {
            this.sidebarMenuStore.setSidebarToogle();
        },
    },
    watch: {

    },
};
</script>

<style scoped>

</style>
