<template>
  <div>
    <div class="gains-slider-container">
      <div class="gains-section">
        <div class="icon-trofeu">
          <img src="/public/storage/trofeu-BPlcGbRU.png" alt="Últimos ganhos de hoje">
        </div>
        <div class="title-gains">
          <h1>
            MAIS RECENTES <br> GANHOS DE HOJE
          </h1>
        </div>
      </div>
      <div class="gains-con">
        <div class="gains-slider">
          <div class="logos-slide">
            <div v-for="(item, index) in items" :key="index" class="slide-gains">
              <img :src="item.game.image" :alt="item.game.game">
              <div class="status-gains">
                <p class="player">{{ item.name }}</p>
                <p class="game">{{ item.game.game }}</p>
                <p class="valor">{{ item.value }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const names = [
      'Camila B****', 'Guilherme S****', 'Rafaela O****', 'Hugo L****', 'Roberta R****',
      'Fernando P****', 'Isabela F****', 'Gabriela R****', 'Camila R****', 'Thales C****',
      'Maria A****', 'Vitor L****', 'Vinícius M****', 'Aline S****', 'Priscila L****',
      'Fernanda A****', 'Mariana C****', 'Rodrigo O****', 'Carla S****', 'Renato A****',
      'Eduardo S****', 'Gabriel N****', 'Camila K****', 'Lucas S****', 'Beatriz F****',
      'Thiago L****', 'Laura R****', 'Carlos A****', 'Mariana G****', 'Rodrigo P****',
      'Ana C****', 'Juliana H****', 'Rafael V****', 'Patrícia M****', 'Vinícius B****',
      'Cristina E****', 'Diego R****', 'Ana Paula L****', 'Fernanda S****', 'Pedro H****',
      'Aline N****', 'Ricardo C****', 'Carla G****', 'Henrique F****', 'Larissa A****',
      'Felipe L****', 'Ana L****', 'Pedro M****', 'Fernanda N****', 'Leonardo M****',
      'Gabriela P****', 'Lucas G****', 'Marcelo A****', 'Letícia R****', 'Thiago P****'
    ];
    const games = [
      { game: 'Fortune Tiger', image: '/assets/images/games/tigre.webp' },
      { game: 'Big Bass Bonanza', image: '/assets/images/games/big.png' },
      { game: 'The Dog House', image: '/assets/images/games/dog.png' },
      { game: 'Prosperity Fortune Tree', image: '/assets/images/games/tree.webp' },
      { game: 'Fruit Party', image: '/assets/images/games/fruit.png' },
      { game: 'Fortune OX', image: '/assets/images/games/ox.webp' },
      { game: 'Sugar Rush', image: '/assets/images/games/sugar.png' },
      { game: 'Fortune Mouse', image: '/assets/images/games/mouse.webp' },
      { game: 'Fortune Rabbit', image: '/assets/images/games/rabbit.webp' },
      { game: 'Ganesha Gold', image: '/assets/images/games/gold.webp' }
    ];

    const getRandomGame = () => {
      return games[Math.floor(Math.random() * games.length)];
    };

    return {
      items: Array.from({ length: 50 }, () => ({
        value: `R$ ${(Math.floor(Math.random() * 900) + 100).toFixed(2)}`,
        name: names[Math.floor(Math.random() * names.length)],
        game: getRandomGame()
      }))
    };
  }
};
</script>

<style scoped>
.gains-slider-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between
}

.gains-section {
    width: 310px;
    height: 100px;
    background: linear-gradient(90deg,rgba(255,172,75,.25) 0%,rgba(255,172,75,0) 100%);
    z-index: 10;
    display: flex;
    align-items: center;
    gap: 15px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.icon-trofeu img {
    width: 90px
}

.gains-con {
    width: calc(100% - 300px);
    overflow: hidden
}

.title-gains h1 {
    color: #fff;
    font-weight: 800;
    font-size: 25px
}

.gains-slider {
    overflow: hidden;
    padding-left: 300px;
    white-space: nowrap;
    position: relative;
    display: flex
}

.gains-slider:before,.gains-slider:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2
}

.gains-slider:before {
    left: 0
}

.gains-slider:after {
    right: 0
}

.gains-slider:hover .logos-slide {
    animation-play-state: paused
}

.logos-slide {
    display: flex;
    animation: slide 110s infinite linear
}

.slide-gains {
    background-color: #1e2024;
    margin-right: 15px;
    width: 250px;
    overflow: hidden;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 10px
}

.slide-gains .player {
    color: #fff;
    font-weight: 500;
    font-size: 17px
}

.slide-gains .game {
    font-size: 14px
}

.slide-gains .valor {
    font-size: 15px;
    color: #ff0;
}

.logos-slide img {
    height: 100px
}

@keyframes slide {
    0% {
        transform: translate(0)
    }

    to {
        transform: translate(-100%)
    }
}

@media only screen and (max-width: 600px) {
    .gains-section {
        width:170px;
        height: 85px
    }

    .icon-trofeu img {
        width: 60px;
    }

    .title-gains h1 {
        font-size: 14px
    }

    .gains-slider {
        padding-left: 170px
    }

    .slide-gains {
        height: 85px
    }

    .gains-con {
        width: calc(100% - 170px);
        overflow: hidden
    }
}
</style>
