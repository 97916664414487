<template>
    <BaseLayout :showFooter="false">
        <LoadingComponent :isLoading="isLoading"></LoadingComponent>
        <div v-if="!isLoading && setting != null" class="mx-auto mt-20">
            <div class="grid grid-cols-1 md:grid-cols-3">
                <!-- <div class="col-span-1 hidden">
                    <WalletSideMenu />
                </div> -->
                <div>
                    <div class="hidden md:block">
                        <div class="flex flex-col h-[calc(100vh-150px)] p-5">
                            <div class="relative bg-card flex flex-col justify-center items-center rounded-lg px-5 py-16 col-span-1">
                                <div @click="toggleVisibility" class="absolute right-5 top-5 toggle-visible cursor-pointer">
                                    <i v-if="isHidden" class="fas fa-eye-slash"></i>
                                    <i v-else class="far fa-eye"></i>
                                </div>
                                <div class="mt-5 text-center">
                                    <p class="text-md text-muted-400">Valor disponível</p>
                                    <div>
                                        <!---->
                                        <h1 class="text-4xl font-bold text-white">{{ isHidden ? '*****' : state.currencyFormat(parseFloat(wallet.balance_withdrawal), wallet.currency) }}</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="relative bg-card flex items-center px-3 py-2 mt-4 rounded-lg w-full cursor-pointer">
                                <div class="flex justify-between items-center gap-4 w-full">
                                    <div class="flex items-center w-full">
                                        <div class="mr-3 text-muted-500">
                                            <i class="fa-light fa-square-dollar text-4xl"></i>
                                        </div>
                                        <div class="block leading-[0.5rem]">
                                            <p class="text-[12px] text-gray-500">Saldo total</p>
                                            <!---->
                                            <h1 class="text-lg font-bold text-white">{{ isHidden ? '*****' : wallet.symbol + ' ' + wallet.total_balance }}</h1>
                                        </div>
                                    </div>
                                    <div class="flex-grow"></div>
                                    <div>
                                        <i class="fa-regular fa-angle-right text-2xl"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="relative bg-card flex items-center px-3 py-2 mt-4 rounded-lg w-full cursor-pointer">
                                <div class="flex justify-between items-center gap-4 w-full">
                                    <div class="flex items-center w-full">
                                        <div class="mr-3 text-muted-500">
                                            <i class="fa-light fa-square-dollar text-4xl"></i>
                                        </div>
                                        <div class="block leading-[0.5rem]">
                                            <p class="text-[12px] text-gray-500">Saldo de bônus</p>
                                            <!---->
                                            <h1 class="text-lg font-bold text-white">{{ isHidden ? '*****' : state.currencyFormat(parseFloat(wallet.balance_bonus), wallet.currency) }}</h1>
                                        </div>
                                    </div>
                                    <div class="flex-grow"></div>
                                    <div>
                                        <i class="fa-regular fa-angle-right text-2xl"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4">
                                <div class="text-left">
                                    <h3 class="uppercase text-muted-400 text-sm font-bold px-1">Rollover</h3>
                                    <div class="grid grid-cols-2 gap-3">
                                        <div class="relative bg-card flex items-center px-3 py-2 mt-4 rounded-lg w-full cursor-pointer">
                                            <div class="flex justify-between items-center gap-4 w-full">
                                                <div class="flex items-center w-full">
                                                    <div class="mr-3 text-muted-500">
                                                        <i class="fa-light fa-square-dollar text-4xl"></i>
                                                    </div>
                                                    <div class="block leading-[0.5rem]">
                                                        <p class="text-[12px] text-gray-500">Rollover de depósito:</p>
                                                        <!---->
                                                        <h1 class="text-lg font-bold text-white">{{ isHidden ? '*****' : state.currencyFormat(parseFloat(wallet.balance_deposit_rollover), wallet.currency) }}</h1>
                                                    </div>
                                                </div>
                                                <div class="flex-grow"></div>
                                                <div>
                                                    <i class="fa-regular fa-angle-right text-2xl"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="relative bg-card flex items-center px-3 py-2 mt-4 rounded-lg w-full cursor-pointer">
                                            <div class="flex justify-between items-center gap-4 w-full">
                                                <div class="flex items-center w-full">
                                                    <div class="mr-3 text-muted-500">
                                                        <i class="fa-light fa-square-dollar text-4xl"></i>
                                                    </div>
                                                    <div class="block leading-[0.5rem]">
                                                        <p class="text-[12px] text-gray-500">Rollover de bônus:</p>
                                                        <!---->
                                                        <h1 class="text-lg font-bold text-white">{{ isHidden ? '*****' : state.currencyFormat(parseFloat(wallet.balance_bonus_rollover), wallet.currency) }}</h1>
                                                    </div>
                                                </div>
                                                <div class="flex-grow"></div>
                                                <div>
                                                    <i class="fa-regular fa-angle-right text-2xl"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <ul>
                                    <li class="w-full mb-2">
                                        <MakeDeposit :showMobile="false" :title="'Depositar'" :WalletPage="true"/>
                                    </li>
                                    <!-- <li class="w-full mb-2" @click.prevent="$router.push('/profile/deposit')">
                                        <button class="transition duration-700 text-white button-primary font-medium rounded-lg text-sm px-5 py-2.5 me-2 focus:outline-none w-full">aaa</button>
                                    </li> -->
                                    <li class="w-full mb-2" @click.prevent="$router.push('/profile/withdraw')">
                                        <button class="transition duration-700 text-white btn-primary-outline font-medium rounded-lg text-sm px-5 py-2.5 me-2 focus:outline-none w-full">Saque</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="block md:hidden">
                        <div class="grid grid-cols-2 gap-3 lg:px-0 px-3">
                            <MakeDeposit :showMobile="true" :title="'Depositar'" :WalletPage="true"/>
                            <button @click.prevent="$router.push('/profile/withdraw')" class="transition duration-700 text-white btn-primary-outline font-medium rounded-lg text-sm px-5 py-2.5 me-2 focus:outline-none w-full !mr-0">Saque</button>
                        </div>
                    </div>
                </div>
                <div class="relative col-span-2 lg:pr-4 lg:px-0 px-3">
                    <div class="flex flex-col w-full">
                        <div class="mt-5 flex flex-col bg-card p-4 rounded-lg mb-20">
                            <div class="flex items-center mb-5">
                                <div>
                                    <h1 class="text-lg md:text-2xl text-muted-400 font-semibold">Informações da Carteira</h1>
                                    <p class="text-muted-500 text-sm md:text-sm">Abaixo você pode ver todas as informações da sua carteira e também ativar sua carteira principal.</p>
                                </div>
                            </div>
                            <div class="text-gray-900 rounded-lg w-full">
                                <button type="button" class="relative inline-flex justify-between items-center w-full px-4 py-3 text-sm font-medium bg-[#152631] rounded-lg">
                                    <div class="flex items-center">
                                        <div class="mr-3">
                                            <img src="/assets/images/money.png" alt="" width="60">
                                        </div>
                                        <div class="flex flex-col items-start">
                                            <div class="text-white">
                                                <strong>Saldo Total:</strong> {{ isHidden ? '*****' : wallet.symbol + ' ' + wallet.total_balance }}
                                            </div>
                                            <p class="text-[12px] text-muted-500 dark:text-muted-400">
                                                <strong>Bônus Total:</strong> {{ isHidden ? '*****' :wallet.symbol + ' ' +  wallet.balance_bonus }}
                                            </p>
                                        </div>
                                    </div>
                                    <span v-if="wallet.active === 1" class="bg-[#047857] text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded flex items-center dark:bg-[#047857] dark:text-green-300">Ativo</span>
                                </button>
                            </div>
                            <div class="block md:hidden text-gray-900 rounded-lg w-full mt-2">
                                <button type="button" class="relative inline-flex justify-between items-center w-full px-4 py-3 text-sm font-medium bg-[#152631] rounded-lg">
                                    <div class="flex items-center">
                                        <div class="mr-3">
                                            <img src="/assets/images/rollover.png" alt="" width="60">
                                        </div>
                                        <div class="flex flex-col items-start">
                                            <div class="text-white">
                                                <strong>Rollover de depósito:</strong> {{ isHidden ? '*****' : state.currencyFormat(parseFloat(wallet.balance_deposit_rollover), wallet.currency) }}
                                            </div>
                                            <p class="text-[12px] text-muted-500 dark:text-muted-400">
                                                <strong>Rollover de bônus::</strong> {{ isHidden ? '*****' : state.currencyFormat(parseFloat(wallet.balance_bonus_rollover), wallet.currency) }}
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div class="mt-5 flex flex-col bg-card rounded-lg">
                                <div class="text-gray-900 rounded-lg w-full">
                                    <button type="button" class="relative inline-flex justify-between items-center w-full px-1 py-2 text-sm font-medium ">
                                        <div class="flex flex-col items-center w-full">
                                            <div v-if="setting.disable_rollover === false || setting.rollover_deposit > 0" class="flex flex-col items-start w-full mb-3">
                                                <div class="py-3 text-white flex gap-2">
                                                    <strong>Rollover de depósito:</strong>
                                                    <span class="text-muted-500 dark:text-muted-400 mb-2 block text-sm font-semibold">(%)</span>
                                                </div>
                                                <div class="w-full">
                                                    <div class="w-full bg-gray-200 rounded-lg dark:bg-gray-700">
                                                        <div class="bg-emerald-700 text-base font-medium text-blue-100 text-center leading-none rounded-lg truncate p-2 px-3" :style="{ width: rolloverPercentage(parseFloat(wallet.balance_deposit_rollover))   }">
                                                            {{ rolloverPercentage(parseFloat(wallet.balance_deposit_rollover)) }}%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="setting.disable_rollover === false || setting.rollover > 0" class="flex flex-col items-start w-full">
                                                <div class="py-3 text-white flex gap-2">
                                                    <strong>Rollover de bônus:</strong>
                                                    <span class="text-muted-500 dark:text-muted-400 mb-2 block text-sm font-semibold">(%)</span>
                                                </div>
                                                <div class="w-full">
                                                    <div class="relative w-full bg-gray-200 rounded-lg dark:bg-gray-700 p-2 mb-2">
                                                        <span class="z-10 relative text-white">{{ rolloverPercentage(parseFloat(wallet.balance_bonus_rollover)) }}% - Barra de Rollover</span>
                                                        <div class="bg-emerald-700 text-base font-medium text-blue-100 text-center leading-none rounded-lg truncate top-0 absolute left-0 h-full" 
                                                        :style="{ width: rolloverPercentage(parseFloat(wallet.balance_bonus_rollover)) + '%' }"></div>
                                                    </div>
                                                    <div class="relative w-full bg-gray-200 rounded-lg dark:bg-gray-700 p-2">
                                                        <span class="z-10 relative text-white">{{ rolloverPercentage(parseFloat(setting.rollover_protection)) }} - Barra de Proteção</span>
                                                        <div class="bg-blue-500 text-base font-medium text-blue-100 text-center leading-none rounded-lg truncate top-0 absolute left-0 h-full"
                                                            :style="{ width: rolloverPercentage(parseFloat(setting.rollover_protection)) + '%' }">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>
<script>

import { RouterLink } from "vue-router";
import BaseLayout from "@/Layouts/BaseLayout.vue";
import WalletSideMenu from "@/Pages/Profile/Components/WalletSideMenu.vue";
import {useToast} from "vue-toastification";
import {useAuthStore} from "@/Stores/Auth.js";
import HttpApi from "@/Services/HttpApi.js";
import {useSettingStore} from "@/Stores/SettingStore.js";
import MakeDeposit from "@/Components/UI/MakeDeposit.vue";
import LoadingComponent from "@/Components/UI/LoadingComponent.vue";

export default {
    props: {
        wallet: {
            type: Object,
            required: true
        }
    },
    components: {WalletSideMenu, BaseLayout, RouterLink, MakeDeposit, LoadingComponent },
    data() {
        return {
            isLoading: true,
            wallet: null,
            mywallets: null,
            setting: null,
            isHidden: false
        }
    },
    setup(props) {


        return {};
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        toggleVisibility() {
            this.isHidden = !this.isHidden;
        },
        setWallet: function(id) {
            const _this = this;
            const _toast = useToast();
            _this.isLoading = true;

            HttpApi.post('profile/mywallet/'+ id, {})
                .then(response => {
                   _this.getMyWallet();
                   _this.isLoading = false;
                    window.location.reload();

                })
                .catch(error => {
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        _toast.error(`${value}`);
                    });
                    _this.isLoading = false;
                });
        },
        getWallet: function() {
            const _this = this;
            const _toast = useToast();
            _this.isLoading = true;

            HttpApi.get('profile/wallet')
                .then(response => {
                    _this.wallet = response.data.wallet;
                    _this.isLoading = false;
                })
                .catch(error => {
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        _toast.error(`${value}`);
                    });
                    _this.isLoading = false;
                });
        },
        getMyWallet: function() {
            const _this = this;
            const _toast = useToast();
            _this.isLoading = true;

            HttpApi.get('profile/mywallet')
                .then(response => {
                    _this.mywallets = response.data.wallets;
                })
                .catch(error => {
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        _toast.error(`${value}`);
                    });
                });
        },
        getSetting: function() {
            const _this = this;
            const settingStore = useSettingStore();
            const settingData = settingStore.setting;

            if(settingData) {
                _this.setting = settingData;
            }
        },
        rolloverPercentage(balance) {
            return Math.max(0, ((balance / 100) * 100).toFixed(2));
        },
    },
    created() {
        this.getWallet();
        this.getMyWallet();
        this.getSetting();
    },
    watch: {

    },
};
</script>

<style scoped>

</style>
