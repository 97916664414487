<template>
    <div v-if="!cookieAccepted" class="flex justify-between items-center gap-3 fixed left-0 md:left-10 bottom-10 z-[999] p-4 bg-primary-opacity shadow-lg border border-muted-0029 rounded-lg text-muted-400">
        <p>Usamos cookies para fornecer a melhor experiência para você.</p>
        <button @click="acceptCookie" class="transition duration-700 text-white button-primary font-medium rounded-lg text-sm px-5 py-2.5 me-2 focus:outline-none">Aceitar</button>
    </div>
</template>

<script>
    export default {
        props: {

        },
        data() {
            return {
                cookieAccepted: localStorage.getItem('cookieAccepted') === 'true',
            };
        },
        methods: {
            acceptCookie() {
                localStorage.setItem('cookieAccepted', 'true');
                this.cookieAccepted = true;
            },
        },
        watch: {

        }
    };
</script>
